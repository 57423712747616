import React, { useMemo } from 'react'
import { Media } from 'utils/api'
import classnames from 'utils/classnames'
import { lazy } from 'utils/lazy'

const LazyVideo = lazy({
  loader: () => import('components/LazyVideo'),
  Fallback: ({ props: { media, className } }) => (
    <img src={media.uri} className={className} />
  ),
})

type Props = {
  media: Media
  classNameForRounding?: string
}

function ProjectMedia({ media, classNameForRounding = 'rounded-md' }: Props) {
  const isVideo = useMemo(
    () => media.mimeType.startsWith('video/'),
    [media.mimeType],
  )

  return (
    <div className={classnames('w-full aspect-square', classNameForRounding)}>
      {isVideo && media.muxPlaybackId !== undefined ? (
        <LazyVideo
          className={classnames(
            'w-full h-full object-contain',
            classNameForRounding,
          )}
          media={media}
        />
      ) : (
        <img
          className={classnames(
            'w-full h-full object-contain',
            classNameForRounding,
          )}
          src={media.uri}
        />
      )}
    </div>
  )
}

export default React.memo(ProjectMedia)
