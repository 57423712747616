import { useEffect, useMemo, useState } from 'react'
import { GetProofResponse, GetTreeResponse, getProof, getTree } from 'lanyard'
import useSWR from 'swr'
import { Hex, decodeAbiParameters } from 'viem'

const lanyardFetcher = async ({
  route,
  args,
}: {
  route: string
  args: unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> => {
  switch (route) {
    case 'tree': {
      const root = args as string
      return getTree(root)
    }
    case 'proof': {
      const { root, address } = args as { root: string; address: string }
      return getProof({ merkleRoot: root, address })
    }
    default:
      throw new Error('Invalid route')
  }
}

const isValidMerkleRootString = (merkleRoot: string) => {
  // 0x + 32 bytes
  return merkleRoot.length === 66 && merkleRoot.startsWith('0x')
}

type MerkleProofForRootError = 'treeNotFound' | 'addressNotInTree' | Error

export const useMerkleProofForRoot = (
  root: string,
  currentWalletAddress: string | undefined,
  useAnyAvailableAddress: boolean,
): {
  proof: Hex[] | undefined
  address: string | undefined
  status: 'loading' | 'error' | 'success'
  error: MerkleProofForRootError | undefined
} => {
  // avoid fetching tree if we don't need to.
  // for large trees (100k+), an entire tree can be multiple MB.
  // this is a lot of data to fetch if we don't need it
  const shouldFetchTree =
    isValidMerkleRootString(root) &&
    useAnyAvailableAddress &&
    currentWalletAddress !== undefined

  const {
    data: treeResponse,
    isValidating: treeIsValidating,
    error: treeError,
  } = useSWR<GetTreeResponse, Error>(
    shouldFetchTree ? { route: 'tree', args: root } : null,
    lanyardFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  const addressForProofLookup = useMemo(() => {
    if (!useAnyAvailableAddress && currentWalletAddress !== undefined) {
      return currentWalletAddress
    }

    if (treeResponse === undefined || treeResponse === null) {
      return undefined
    }

    if (
      treeResponse.leafTypeDescriptor === null ||
      treeResponse.leafTypeDescriptor.length === 1
    ) {
      // best guess is it is an address
      return treeResponse.unhashedLeaves[0]
    }

    // decode the leaf type descriptor
    let addressIndex = -1
    for (let i = 0; i < treeResponse.leafTypeDescriptor.length; i++) {
      const leafType = treeResponse.leafTypeDescriptor[i]
      if (leafType === 'address') {
        if (addressIndex !== -1) {
          // found multiple address arguments, can't guess
          return undefined
        }
        addressIndex = i
      }
    }

    // couldn't find address
    if (addressIndex === -1) {
      return undefined
    }

    try {
      const decoded = decodeAbiParameters(
        treeResponse.leafTypeDescriptor,
        treeResponse.unhashedLeaves[0] as `0x${string}`,
      )
      return decoded[addressIndex]
    } catch (e) {
      console.error('failed to decode leaf type descriptor', e)
      return undefined
    }
  }, [currentWalletAddress, treeResponse, useAnyAvailableAddress])

  const proofFetchKey = useMemo(() => {
    if (!isValidMerkleRootString(root)) return null
    if (addressForProofLookup === undefined) return null
    return { route: 'proof', args: { root, address: addressForProofLookup } }
  }, [addressForProofLookup, root])

  const {
    data: proofResponse,
    isValidating: rootIsValidating,
    error: proofError,
  } = useSWR<GetProofResponse, Error>(proofFetchKey, lanyardFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const [error, errorSet] = useState<MerkleProofForRootError | undefined>(
    undefined,
  )

  useEffect(() => {
    if (treeResponse === null) {
      errorSet('treeNotFound')
      return
    }

    if (treeError !== undefined) {
      errorSet(treeError)
      return
    }

    if (proofResponse === null) {
      errorSet('addressNotInTree')
      return
    }

    if (proofError !== undefined) {
      errorSet(proofError)
      return
    }

    errorSet(undefined)
  }, [
    useAnyAvailableAddress,
    proofError,
    proofResponse,
    treeError,
    treeResponse,
  ])

  const status = useMemo(
    () =>
      error !== undefined
        ? 'error'
        : treeIsValidating || rootIsValidating
        ? 'loading'
        : 'success',
    [error, treeIsValidating, rootIsValidating],
  )

  const addressForReturn = useMemo(() => {
    if (!useAnyAvailableAddress) {
      return currentWalletAddress
    }

    // if we don't have a valid root, return the current address
    if (!isValidMerkleRootString(root)) {
      return currentWalletAddress
    }

    return addressForProofLookup
  }, [
    useAnyAvailableAddress,
    currentWalletAddress,
    addressForProofLookup,
    root,
  ])

  return {
    proof: proofResponse?.proof as Hex[] | undefined,
    address: String(addressForReturn),
    status,
    error,
  }
}
