import React, { useMemo } from 'react'
import { CollectionSettings } from 'utils/api'
import classNames from 'utils/classnames'
import { useSidebarOpen } from 'utils/useLayout'
import ProjectMedia from './ProjectMedia'

const GridPlaceholder = () => (
  <div className="grid-placeholder aspect-square rounded-md w-full col-span-2" />
)

type Props = {
  settings: CollectionSettings
}

function ProjectMediaGrid({ settings }: Props) {
  const remainder = useMemo(() => settings.media.length % 3, [settings.media])
  const sidebarOpen = useSidebarOpen()

  if (settings.media.length === 0) {
    return null
  }

  return (
    <div
      className={classNames('w-full md:w-4/5 grid grid-cols-6 gap-2 md:gap-4')}
    >
      {settings.media.length === 1 && sidebarOpen ? (
        <>
          <GridPlaceholder />
          <div className="col-span-2 h-fit">
            <ProjectMedia media={settings.media[0]} />
          </div>
          <GridPlaceholder />
        </>
      ) : (
        settings.media.map((media, index) => (
          <div
            key={`${media.hash}-${index}`}
            className={classNames(
              'col-span-2 h-fit',
              remainder === 1 &&
                index === settings.media.length - 1 &&
                'col-start-3',
              remainder === 2 &&
                index === settings.media.length - 2 &&
                'col-start-2',
            )}
          >
            <ProjectMedia media={media} />
          </div>
        ))
      )}
    </div>
  )
}

export default React.memo(ProjectMediaGrid)
