import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import { CollectionSettings } from 'utils/api'
import classNames from 'utils/classnames'

type Props = {
  settings: CollectionSettings
}

function DescriptionSection({ settings }: Props) {
  const { description } = settings
  const descriptionLines = useMemo(() => {
    if (description === undefined) return []
    return description.split('\n')
  }, [description])

  const [expanded, expandedSet] = useState(false)
  const [justExpanded, justExpandedSet] = useState(false)

  const descriptionRef = useRef<HTMLDivElement>(null)
  const { width, height } = useResizeObserver({ ref: descriptionRef })
  const isClamped = useMemo(() => {
    if (!descriptionRef.current) {
      return false
    }

    const { scrollHeight, clientHeight } = descriptionRef.current
    return scrollHeight > clientHeight

    // we include these in the deps to re-run this effect when they change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, description, descriptionLines])

  const toggleExpanded = useCallback(() => {
    expandedSet((prev) => !prev)
    // ugly hack bc the isClamped gets set to false
    // pls remove if possible and don't check the blame
    justExpandedSet(true)
    const cancelId = setTimeout(() => {
      justExpandedSet(false)
    }, 100)

    return () => {
      clearTimeout(cancelId)
    }
  }, [])

  // description from the server shouldn't ever be an empty string, but due to
  // the wy the sidebar is set up, it can be set to an empty string while editing
  if (description === undefined || description === '') {
    return null
  }

  return (
    <div
      className={classNames(
        'w-full',
        'py-3 md:py-6 px-4 md:px-8',
        'border-2 border-neutral-200 dark:border-neutral-700',
        'flex flex-col items-start gap-y-2',
      )}
    >
      <div className="font-bold text-base">Description</div>
      <div
        className={classNames(
          'text-sm',
          // See mint.fun/0x9915e39c9a6c916296c97a7598a3969b1f7ab52b
          // for why we need max-w-full and break-words
          'break-words max-w-full normal-case',
          !expanded && 'line-clamp-3',
        )}
        ref={descriptionRef}
      >
        {descriptionLines.map((line, i) => (
          <Fragment key={i}>
            {line}
            {i !== descriptionLines.length - 1 && <br />}
          </Fragment>
        ))}
      </div>
      {(isClamped || expanded || justExpanded) && (
        <button
          className="text-sm font-bold underline"
          onClick={toggleExpanded}
        >
          {expanded ? 'Show less' : 'Show more'}
        </button>
      )}
    </div>
  )
}

export default React.memo(DescriptionSection)
