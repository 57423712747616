import React, { useMemo } from 'react'
import { withErrorBoundary } from '@sentry/nextjs'
import { collectionIsMintedOut } from 'components/Project/utils'
import { themeToVariant, useTransactionGroups } from './utils'
import { Collection, CollectionSettings, MintSource } from 'utils/api'
import GroupSelector from './GroupSelector'

type Props = {
  collection: Collection
  settings: CollectionSettings
  source: MintSource
}

function TransactionList({ collection, settings, source }: Props) {
  const isMintedOut = useMemo(
    () => collectionIsMintedOut(collection) ?? false,
    [collection],
  )

  const transactionGroups = useTransactionGroups(
    collection.contract,
    isMintedOut,
  )

  if (transactionGroups === undefined) {
    return null
  }

  return (
    <GroupSelector
      variant={themeToVariant[settings.theme]}
      groups={transactionGroups}
      collection={collection}
      settings={settings}
      source={source}
    />
  )
}

export default withErrorBoundary(React.memo(TransactionList), {})
