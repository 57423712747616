import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Collection } from 'utils/api'
import { s } from 'utils/helpers'
import { formatCountdownShort } from 'utils/time'
import { tryBigNumber } from 'utils/web3/helpers'

/**
 * NB: Only used in the case where we don't have the countdown. If we need the countdown, we need to refactor
 * this to have more context.
 */
export const useWillSubtitleRender = (collection: Collection) => {
  return useSubtitleElements(collection, undefined, undefined).length > 0
}

const useSubtitleElements = (
  collection: Collection,
  dateDiff: number | undefined,
  mintsLastHour: string | undefined,
) => {
  const latestMintsLastHr = useMemo(() => {
    if (mintsLastHour !== undefined) {
      const mints = tryBigNumber(mintsLastHour)
      if (mints !== undefined) {
        return mints
      }
    }

    return tryBigNumber(collection.mintsLastHour.toString()) ?? 0n
  }, [collection.mintsLastHour, mintsLastHour])

  return useMemo<JSX.Element[]>(() => {
    const elements: JSX.Element[] = []

    if (latestMintsLastHr > 0n) {
      elements.push(
        <Fragment key="last-hr">
          {`${latestMintsLastHr.toLocaleString()} mint${s(
            latestMintsLastHr,
          )} last hr`}
        </Fragment>,
      )
    }

    const maxSupply =
      collection.maxSupply !== undefined
        ? tryBigNumber(collection.maxSupply)
        : undefined

    if (maxSupply !== undefined) {
      const totalMints = tryBigNumber(collection.totalMints) ?? 0n
      if (maxSupply === totalMints) {
        return [<Fragment key="100-minted">100% minted</Fragment>]
      }

      const percentage = (totalMints * 100n) / maxSupply
      const maxMaxSupplyBeforeHidePercentage = 1000000n

      if (percentage < 100n && maxSupply < maxMaxSupplyBeforeHidePercentage) {
        elements.push(
          <Fragment key="minted-percent">{`${percentage}% minted`}</Fragment>,
        )
      }
    }

    if (dateDiff !== undefined && collection.nextMintEnd !== undefined) {
      if (dateDiff >= 0) {
        const date = new Date(collection.nextMintEnd)
        const lessThanAMinuteLeft = dateDiff < 60_000 // 1 minute

        elements.push(
          <span key="mint-end" title={date.toLocaleString()}>
            <span
              className={lessThanAMinuteLeft ? 'text-error-text' : undefined}
            >
              {formatCountdownShort(dateDiff)}
            </span>{' '}
            remaining
          </span>,
        )
      }
    }

    if (elements.length === 0) {
      return elements
    }

    const joinedElements: JSX.Element[] = []
    for (let i = 0; i < elements.length; i++) {
      joinedElements.push(elements[i])

      if (i < elements.length - 1) {
        joinedElements.push(<Fragment key={`b-${i}`}>{' • '}</Fragment>)
      }
    }

    return joinedElements
  }, [
    collection.maxSupply,
    collection.nextMintEnd,
    collection.totalMints,
    dateDiff,
    latestMintsLastHr,
  ])
}

type Props = {
  collection: Collection
  mintsLastHour?: string
  includeCountdown?: boolean
  className?: string
}

function ProjectSubtitleText({
  collection,
  mintsLastHour,
  includeCountdown = true,
  className,
}: Props) {
  // ideally we would render this by passing in a function for the first render
  // but SSR doesn't work with dates compared to client time
  const [dateDiff, dateDiffSet] = useState<number | undefined>()

  useEffect(() => {
    if (!includeCountdown) {
      dateDiffSet(undefined)
      return
    }

    if (collection.nextMintEnd === undefined) {
      dateDiffSet(undefined)
      return
    }

    const date = new Date(collection.nextMintEnd)
    const reloadIt = () => {
      const diff = date.getTime() - new Date().getTime()
      dateDiffSet(diff)
    }
    reloadIt()

    const interval = setInterval(reloadIt, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [collection.nextMintEnd, includeCountdown])

  const elements = useSubtitleElements(collection, dateDiff, mintsLastHour)

  if (elements.length === 0) {
    return null
  }

  return <span className={className}>{elements}</span>
}

export default React.memo(ProjectSubtitleText)
