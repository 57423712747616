import { ButtonLink } from 'components/Button'
import { makeContract } from 'utils/api'
import { AddressHash, Contract, isContractEqual } from 'utils/api'
import classnames from 'utils/classnames'

type Props = {
  contract: Contract
}

const finiAddress = '0x085115c88af1472b784695fd2f408dbdab29056a' as AddressHash

const finiContract = makeContract({
  address: finiAddress,
})

/* TODO: replace this with backend data once that's setup */
const data = [
  {
    title: 'Fini iOS App',
    text: 'Gain early access to the Fini app beta and create iPhone widgets for your Zapper Fini.',
    buttonText: 'Install',
    url: 'https://app.fini.world/',
    image: 'https://media.contextcdn.com/partnerships/fini.png',
  },
  {
    title: 'Track Your Portfolio',
    text: 'Unlock 24h performance for your entire portfolio on Zapper, including your tokens, NFTs, and DeFi.',
    buttonText: 'Visit',
    url: 'https://zapper.xyz/',
    image: 'https://media.contextcdn.com/partnerships/zapper.jpg',
  },
]

export default function CustomCTALinks({ contract }: Props) {
  if (!isContractEqual(contract, finiContract)) {
    return null
  }

  return (
    <div
      className={classnames(
        'border border-neutral-200 dark:border-neutral-800',
        'rounded-md p-3 md:p-6 md:pr-12 flex flex-col gap-y-6 max-w-xl',
      )}
    >
      {data.map((link) => (
        <div key={link.url} className="flex gap-3 md:gap-4">
          <div
            className="w-10 h-10 md:w-32 md:h-32 rounded-md"
            style={{
              backgroundImage: `url(${link.image})`,
              backgroundSize: '100%',
            }}
          />
          <div className="flex flex-col flex-1 gap-y-1 md:gap-y-2">
            <h4 className="font-bold text-sm">{link.title}</h4>
            <p className="text-xs">{link.text}</p>
            <div className="inline-flex">
              <ButtonLink
                className="mt-2 inline-flex text-xs font-normal hover:bg-neutral-200 dark:hover:bg-neutral-700"
                variant="outline"
                label={link.buttonText}
                href={link.url}
                target="_blank"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
