import { useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import {
  Contract,
  ContractTransaction,
  ContractTransactionsResponse,
  ProjectTheme,
  contractTransactionsEndpoint,
  useAPIResponseForAddress,
} from 'utils/api'
import {
  TransactionGroup,
  TransactionWithPreparedResult,
  Variant,
} from './types'

export const themeToVariant: Record<ProjectTheme, Variant> = {
  default: 'list',
  minimal: 'plusMinus',
  grid: 'plusMinus',
}

export const themeToButtonClasses: Record<ProjectTheme, string | undefined> = {
  default: undefined,
  minimal: 'md:w-[35.5rem] md:h-[4.7rem]',
  grid: 'md:w-[35.5rem] md:h-[4.7rem]',
}

export const transactionKey = (tx: ContractTransaction) =>
  `${tx.callData}-${tx.ethValue}-${tx.nftCount}-${String(
    tx.isAllowlist,
  )}-${String(tx.isValid)}`

export const sortTransactions = (
  a: ContractTransaction,
  b: ContractTransaction,
): number => {
  // sort by valid, then lowest nft count, then lowest price, then highest minter count

  if (a.isValid !== b.isValid) {
    return a.isValid ? -1 : 1
  }

  const nftCount = BigInt(a.nftCount) - BigInt(b.nftCount)
  if (nftCount !== 0n) {
    return nftCount < 0n ? -1 : 1
  }

  const aEth = BigInt(a.ethValue)
  const bEth = BigInt(b.ethValue)
  if (aEth < bEth) {
    return -1
  } else if (aEth > bEth) {
    return 1
  }

  return b.minterCount - a.minterCount
}

export const useTransactionGroups = (
  contract: Contract,
  isMintedOut: boolean,
): TransactionGroup[] | undefined => {
  const { data } = useAPIResponseForAddress<ContractTransactionsResponse>(
    contractTransactionsEndpoint({ contract }),
    undefined,
    { skipFetching: isMintedOut },
  )

  const groups = useMemo<TransactionGroup[] | undefined>(() => {
    if (isMintedOut) {
      return []
    }

    if (data === undefined) {
      return undefined
    }

    const allowlistGroup: TransactionGroup = {
      transactions: [],
      isAllowlist: true,
    }
    const publicGroup: TransactionGroup = {
      transactions: [],
      isAllowlist: false,
    }

    for (const tx of data.transactions) {
      if (!tx.isValid) {
        continue
      }

      if (tx.isAllowlist) {
        allowlistGroup.transactions.push(tx)
      } else {
        publicGroup.transactions.push(tx)
      }
    }

    allowlistGroup.transactions.sort(sortTransactions)
    publicGroup.transactions.sort(sortTransactions)

    const groups: TransactionGroup[] = []
    if (allowlistGroup.transactions.length > 0) {
      groups.push(allowlistGroup)
    }
    if (publicGroup.transactions.length > 0) {
      groups.push(publicGroup)
    }

    return groups
  }, [data, isMintedOut])

  return groups
}

export const getTransactionsForFiltered = (
  txsWithResult: TransactionWithPreparedResult[],
  maxTxs: number,
): TransactionWithPreparedResult[] => {
  // select the cheapest option with the most minters that is valid
  let cheapestIndex = 0
  for (let i = 1; i < txsWithResult.length; i++) {
    const current = txsWithResult[i]
    const cheapest = txsWithResult[cheapestIndex]

    const price = BigInt(current.transaction.ethValue)
    const cheapestNum = BigInt(cheapest.transaction.ethValue)

    if (
      price < cheapestNum ||
      (price === cheapestNum &&
        current.transaction.minterCount > cheapest.transaction.minterCount)
    ) {
      cheapestIndex = i
    }
  }

  let topByMinterCount = [...txsWithResult].sort(
    (a, b) => b.transaction.minterCount - a.transaction.minterCount,
  )

  // allow 1 extra before showing collapsed view
  if (topByMinterCount.length > maxTxs + 1) {
    topByMinterCount = topByMinterCount.slice(0, maxTxs)
  }

  const cheapest = txsWithResult[cheapestIndex]

  // if the cheapest transaction is not in the popular transactions, add it and remove the least popular
  if (
    !topByMinterCount.some((val) =>
      isEqual(val.transaction, cheapest.transaction),
    )
  ) {
    topByMinterCount.pop()
    topByMinterCount.push(cheapest)
  }

  // finally, use sortTransactions to sort the transactions
  return topByMinterCount.sort((a, b) =>
    sortTransactions(a.transaction, b.transaction),
  )
}
