import React from 'react'
import Report from 'components/Report'
import {
  CollectionSettings,
  Contract,
  ContractTransactionsResponse,
  contractTransactionsEndpoint,
  useAPIResponseForAddress,
  useCollectionDetails,
  useCollectionResponse,
} from 'utils/api'
import classNames from 'utils/classnames'
import AllowlistDetailsBox from './AllowlistDetailsBox'
import Details from './Details'
import { Section, SectionContainer } from './Section'
import TopMinters, { usePrefetchMintCount } from './TopMinters'
import Velocity from './Velocity'

type Props = {
  contract: Contract
  settings: CollectionSettings
  className?: string
}

function DetailsSection({ contract, settings, className }: Props) {
  const collectionResponse = useCollectionResponse(contract)

  usePrefetchMintCount(contract)

  const { data: transactionData } =
    useAPIResponseForAddress<ContractTransactionsResponse>(
      contractTransactionsEndpoint({ contract }),
      undefined,
    )

  const { data: detailsResponse } = useCollectionDetails(contract)

  if (collectionResponse === undefined) {
    return null
  }

  const { collection, profiles } = collectionResponse
  const allowlist = transactionData?.allowlist ?? collection.allowlist
  const hideStats = settings.hideStats ?? false

  return (
    <div className={className}>
      <AllowlistDetailsBox allowlist={allowlist} />

      {hideStats ? (
        <div
          className={classNames(
            'grid grid-cols-1',
            'gap-0.5 bg-neutral-200 dark:bg-neutral-700',
            'border-2 border-neutral-200 dark:border-neutral-700',
          )}
        >
          <SectionContainer>
            <Section title="Details">
              <Details
                collection={collection}
                profiles={profiles}
                settings={settings}
              />
            </Section>
          </SectionContainer>
        </div>
      ) : (
        <div
          className={classNames(
            'grid grid-flow-row md:grid-flow-col',
            'grid-cols-1 md:grid-cols-2',
            // inner border is solved by setting the background color of each
            // section to the same color as the page background
            // and then setting a grid gap and background color
            // Ref: https://stackoverflow.com/a/49556470
            'gap-0.5 bg-neutral-200 dark:bg-neutral-700',
            'border-2 border-neutral-200 dark:border-neutral-700',
          )}
        >
          <SectionContainer className="row-span-2">
            <Section title="Details">
              <Details
                collection={collection}
                profiles={profiles}
                settings={settings}
              />
            </Section>
          </SectionContainer>
          <SectionContainer
            className={classNames(
              detailsResponse !== undefined &&
                detailsResponse.details.topMinters.length === 0
                ? 'row-span-2'
                : 'col-span-1',
            )}
          >
            <Section title="Mint history">
              <Velocity contract={contract} />
            </Section>
          </SectionContainer>
          {detailsResponse !== undefined &&
            detailsResponse.details.topMinters.length > 0 && (
              <SectionContainer className="col-span-1">
                <Section title="Top minters">
                  <TopMinters collection={collection} />
                </Section>
              </SectionContainer>
            )}
        </div>
      )}

      <div className="w-full flex justify-center mt-6">
        <Report collectionContract={contract} />
      </div>
    </div>
  )
}

export default React.memo(DetailsSection)
