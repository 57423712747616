import { useMemo } from 'react'
import {
  Collection,
  Contract,
  ContractTransactionsResponse,
  MintStatus,
  contractTransactionsEndpoint,
  useAPIResponseForAddress,
  useCollectionResponse,
} from 'utils/api'
import { tryBigNumber } from 'utils/web3/helpers'

export const useProjectMintStatus = (
  contract: Contract,
): MintStatus | undefined => {
  const collectionResponse = useCollectionResponse(contract)
  const { data: transactionsData } =
    useAPIResponseForAddress<ContractTransactionsResponse>(
      contractTransactionsEndpoint({ contract }),
    )

  const isMintedOut = useMemo(
    () => collectionIsMintedOut(collectionResponse?.collection),
    [collectionResponse?.collection],
  )

  const hasMintableTxs = useMemo(
    () => transactionsData?.transactions.some((tx) => tx.isValid),
    [transactionsData?.transactions],
  )

  if (isMintedOut === true) {
    return 'mintedOut'
  }

  if (transactionsData === undefined || hasMintableTxs === undefined) {
    return undefined
  }

  const totalMints =
    collectionResponse?.collection.totalMints !== undefined
      ? tryBigNumber(collectionResponse.collection.totalMints)
      : undefined

  if (totalMints !== undefined && totalMints === 0n) {
    return 'noMints'
  }

  if (!hasMintableTxs && (totalMints ?? 0n) > 0n) {
    return 'notAvailable'
  }

  if (hasMintableTxs) {
    return 'mintingNow'
  }

  return undefined
}

export const collectionIsMintedOut = (
  collection: Collection | undefined,
): boolean | undefined => {
  if (collection === undefined || collection.maxSupply === undefined) {
    return undefined
  }

  const maxSupply = tryBigNumber(collection.maxSupply)
  const totalMints = tryBigNumber(collection.totalMints)

  if (maxSupply === undefined || totalMints === undefined) {
    return undefined
  }

  return totalMints >= maxSupply
}
