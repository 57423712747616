export const formatPerWalletText = (
  minterCount: bigint,
  totalMints: bigint,
): string | undefined => {
  if (minterCount <= 0n) {
    return undefined
  }

  // in my testing, this never throws but i think it should. can remove if someone
  // smarter than me can explain why the test case doesn't throw here
  try {
    const precision = 100
    const perWallet =
      Number((totalMints * BigInt(precision)) / minterCount) / precision

    return `(${perWallet.toLocaleString()} per wallet)`
  } catch {
    return undefined
  }
}

export const formatConsolidationPercentText = (
  minterCount: bigint,
  totalMints: bigint,
): string | undefined => {
  if (totalMints <= 0n) {
    return undefined
  }

  return `(${(minterCount * 100n) / totalMints}%)`
}
