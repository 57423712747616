import React from 'react'
import { withErrorBoundary } from '@sentry/nextjs'
import NFTCarousel from 'components/NFTCarousel'
import {
  Contract,
  SampleNFTsResponse,
  contractSampleNFTsEndpoint,
  useAPIResponse,
} from 'utils/api'
import classnames from 'utils/classnames'

type Props = {
  contract: Contract
  className?: string
}

function RecentMints({ contract, className }: Props) {
  const { data: sampleNfts } = useAPIResponse<SampleNFTsResponse>(
    contractSampleNFTsEndpoint({ contract }),
    undefined,
    { refreshInterval: 1000 * 15 },
  )

  if (sampleNfts === undefined || sampleNfts.recent.length === 0) {
    return null
  }

  return (
    <div className={classnames('flex flex-col gap-y-6', className)}>
      <div className="text-xl font-bold">Recent mints</div>
      <NFTCarousel nftsResponse={sampleNfts} size="mobile" />
      <NFTCarousel nftsResponse={sampleNfts} size="tablet" />
      <NFTCarousel nftsResponse={sampleNfts} size="desktop" />
    </div>
  )
}

export default React.memo(withErrorBoundary(RecentMints, {}))
