import React, { useCallback, useMemo } from 'react'
import { useBoolean } from 'hooks/useBoolean'
import Link from 'next/link'
import { formatEther } from 'viem'
import { DialogContent, Modal } from 'components/Modal'
import Media from 'components/NFTView/Media'
import { Contract } from 'utils/api'
import { getContractPath } from 'utils/chains'
import { useAccountOrLocalCheckpoint } from 'utils/checkpoints'
import classnames from 'utils/classnames'
import { referralFee } from 'utils/constants'
import { lazy } from 'utils/lazy'
import { ShareCollectionButtons } from 'utils/toasts/TransactionToast'
import { useAccount } from 'utils/useAccount'
import { useSidebarOpen, useSidebarToggle } from 'utils/useLayout'
import { getReferralLink } from 'utils/web3/helpers'

const commonClasses = classnames(
  'flex items-center justify-center',
  'w-9 h-9 rounded-xl',
  'bg-white/20 backdrop-blur-sm',
)

const ProjectSettingsCog = lazy({
  loader: () => import('components/icons/ProjectSettingsCog'),
  Fallback: () => <svg width={16} height={16} />,
})

const CustomizeWand = lazy({
  loader: () => import('components/icons/CustomizeWand'),
  Fallback: () => null,
})

const CopyIcon = lazy({
  loader: () => import('components/icons/CopyIcon'),
  Fallback: () => null,
})

type ProjectSettingsButtonProps = {
  contract: Contract
}

export function ProjectSettingsButton({
  contract,
}: ProjectSettingsButtonProps) {
  return (
    <Link
      className={commonClasses}
      href={`${getContractPath(contract)}/settings/mint-options`}
    >
      <ProjectSettingsCog />
    </Link>
  )
}

export function CustomizeSettingsButton() {
  const isOpen = useSidebarOpen()
  const onToggle = useSidebarToggle()

  const [, markBannerAsSeen] = useAccountOrLocalCheckpoint(
    'customize-cta-banner',
    false,
  )

  const handleClick = useCallback(() => {
    onToggle()
    markBannerAsSeen()
  }, [markBannerAsSeen, onToggle])

  return (
    <button
      className={classnames(commonClasses, isOpen && 'bg-white text-black')}
      onClick={handleClick}
    >
      <CustomizeWand />
    </button>
  )
}

type ShareLinkButtonProps = {
  contract: Contract
  collectionName: string
  isFunContract: boolean
}

export function ShareLinkButton({
  contract,
  collectionName,
  isFunContract,
}: ShareLinkButtonProps) {
  const {
    state: isOpen,
    setTrue: openModal,
    setFalse: closeModal,
  } = useBoolean(false)
  const { address } = useAccount()

  const shareLink = useMemo(() => {
    return getReferralLink(contract, address)
  }, [address, contract])

  return shareLink === '' ? null : (
    <Modal
      trigger={
        <button className={classnames(commonClasses)} onClick={openModal}>
          <CopyIcon />
        </button>
      }
      open={isOpen}
      onClose={closeModal}
      content={
        <div className="flex flex-col gap-y-5">
          <div className="flex gap-x-4">
            <Media
              height={86}
              width={86}
              collectionContract={contract}
              tokenId="1"
              className="max-h-20 w-[86px] h-[86px] rounded-md"
            />
            <div className="flex flex-col">
              <div className="text-left text-xl font-bold leading-tight mb-[7px] max-w-sm">
                Share <span className="normal-case">{collectionName}</span>
              </div>
              {isFunContract ? (
                <div className="text-left max-w-xs">
                  Earn{' '}
                  <span className="font-bold">
                    {formatEther(referralFee)}{' '}
                    <span className="uppercase">ETH</span>
                  </span>{' '}
                  in rewards when someone mints using your referral link.
                </div>
              ) : (
                <div className="text-left max-w-xs md:mr-10">
                  Share this project on X or Farcaster. Only projects created on
                  mint.fun are eligible for referral rewards.
                </div>
              )}
            </div>
          </div>
          <ShareCollectionButtons
            isFunContract={isFunContract}
            collectionContract={contract}
            collectionName={collectionName}
            minterAddress={address}
          />
        </div>
      }
      DialogContent={({ children, className }) => (
        <DialogContent asChild>
          <div
            className={classnames(
              className,
              'w-full xs:w-full pt-12 px-6 pb-6 min-h-0',
            )}
          >
            {children}
          </div>
        </DialogContent>
      )}
    />
  )
}
