import React, { useMemo } from 'react'
import { useBreakpoints } from 'react-match-breakpoints'
import {
  Contract,
  MintTimesResponse,
  contractMintTimesEndpoint,
  useAPIResponse,
} from 'utils/api'
import { lazy } from 'utils/lazy'
import { useHasMounted } from 'utils/react-helpers'

const MintTimesChart = lazy({
  loader: () => import('./MintTimesChart'),
  Fallback: () => null,
})

type Props = {
  contract: Contract
}

function Velocity({ contract }: Props) {
  const bp = useBreakpoints()

  const { data: mintTimesResponse } = useAPIResponse<MintTimesResponse>(
    contractMintTimesEndpoint({ contract }),
    undefined,
    { refreshInterval: 1000 * 60 },
  )

  const data = useMemo<{ value: number; time: number }[] | undefined>(() => {
    if (mintTimesResponse === undefined) return undefined

    let totalMinted = 0
    const result: { value: number; time: number }[] = []
    for (const [mintTimeStr, count] of mintTimesResponse) {
      const mintTime = new Date(mintTimeStr)
      const numberCount = Number(count)
      result.push({
        value: numberCount + totalMinted,
        time: mintTime.getTime(),
      })
      totalMinted += numberCount
    }
    return result
  }, [mintTimesResponse])

  const { startTime, endTime } = useMemo(() => {
    if (data === undefined) return {}

    const startTime = new Date(data[0]?.time)
    const endTime = new Date(data[data.length - 1]?.time)

    return { startTime, endTime }
  }, [data])

  const hasMounted = useHasMounted()
  const chartHeight = hasMounted ? (bp.md ? 170 : 150) : 0

  if (data === undefined) {
    // 24 is the line height of text-base
    const loadingHeight = chartHeight + 24
    return <div style={{ height: loadingHeight }} />
  }

  if (data.length === 0 || startTime === undefined || endTime === undefined) {
    return <div className="text-center py-6">No mints yet</div>
  }

  if (data.length < 2) {
    return <div className="text-center py-6">Waiting for more mints</div>
  }

  return (
    <div className="flex flex-col">
      <div style={{ height: chartHeight }}>
        <MintTimesChart data={data} height={chartHeight} />
      </div>
      <div className="flex justify-between">
        <span title={startTime.toLocaleString()}>
          {startTime.toLocaleDateString()}
        </span>
        <span title={endTime.toLocaleString()}>
          {endTime.toLocaleDateString()}
        </span>
      </div>
    </div>
  )
}

export default React.memo(Velocity)
