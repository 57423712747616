import React from 'react'
import { ButtonLink } from 'components/Button'
import { Modal, ModalClose } from 'components/Modal'
import InfoIcon from 'components/icons/InfoIcon'
import { ContractAllowlistState } from 'utils/api'
import classNames from 'utils/classnames'
import { textSecondary } from 'utils/theme'
import { useAccount } from 'utils/useAccount'
import { useOpenConnectModal } from 'utils/web3/walletconnect'

const AllowlistDetailsBox = ({
  allowlist,
}: {
  allowlist: ContractAllowlistState
}) => {
  const { isWalletConnected } = useAccount()
  const { openConnectModal } = useOpenConnectModal()

  if (allowlist === 'none') {
    return null
  }

  return (
    <div
      className={classNames(
        'border-x-2 border-t-2 border-neutral-200 dark:border-neutral-700',
        'flex items-center gap-x-3',
        'p-6',
      )}
    >
      <InfoIcon className="flex-shrink-0" />
      <div className="text-sm md:text-base">
        {(() => {
          switch (allowlist) {
            case 'unknownAllowlist':
              return (
                <>
                  An allowlist was found but has not been shared with mint.fun.
                  If you are the project owner,{' '}
                  <Modal
                    trigger={
                      <a className="underline cursor-pointer" type={undefined}>
                        share it here
                      </a>
                    }
                    title="Connect your allowlist to mint.fun"
                    description="If you are the creator of this project, use Lanyard to share your allowlist with mint.fun"
                    content={
                      <div className="flex flex-col gap-2 w-full">
                        <ButtonLink
                          label="Go to Lanyard"
                          href="https://lanyard.org"
                        />

                        <ModalClose asChild>
                          <button
                            className={classNames(
                              textSecondary,
                              'text-xs font-semibold p-2',
                            )}
                          >
                            I’ll do this later
                          </button>
                        </ModalClose>
                      </div>
                    }
                  />
                  .
                </>
              )
            case 'knownAllowlist':
              if (isWalletConnected) {
                return 'An allowlist was found in the contract, but you are not on it.'
              }

              return (
                <>
                  An allowlist was found in the contract.{' '}
                  <a
                    className="underline cursor-pointer"
                    onClick={openConnectModal}
                  >
                    Connect your wallet
                  </a>{' '}
                  to see if you’re on the allowlist.
                </>
              )
            case 'addressOnKnownAllowlist':
              return 'You are on the allowlist for this project!'
            case 'alreadyMinted':
              return 'You have already minted from the allowlist for this project.'
          }
        })()}
      </div>
    </div>
  )
}

export default React.memo(AllowlistDetailsBox)
