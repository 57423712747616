import ProjectMedia from 'components/ProjectMedia'
import { CollectionSettings } from 'utils/api'
import classNames from 'utils/classnames'
import { lazy } from 'utils/lazy'

const Carousel = lazy({
  loader: () => import('./Carousel'),
  Fallback: ({ props: { settings } }) => (
    <ProjectMedia media={settings.media[0]} classNameForRounding="rounded-xl" />
  ),
})

type Props = {
  settings: CollectionSettings
  className?: string
}

function ProjectMediaCarousel({ settings, className }: Props) {
  if (settings.media.length === 0) {
    return null
  }

  if (settings.media.length === 1) {
    return (
      <div className={classNames('shrink-0', className)}>
        <ProjectMedia
          media={settings.media[0]}
          classNameForRounding="rounded-xl"
        />
      </div>
    )
  }

  return (
    <div className={classNames('shrink-0 group', className)}>
      <Carousel settings={settings} />
    </div>
  )
}

export default ProjectMediaCarousel
