import { memo, useCallback } from 'react'
import { SweepModal } from '@reservoir0x/reservoir-kit-ui'
import { Button } from 'components/Button'
import { Collection, getAddress } from 'utils/api'
import { useOpenCorrectNetwork } from 'utils/chains'
import { referrerFee } from 'utils/constants'
import { contextTreasuryAddress } from 'utils/treasury'
import { useAccount } from 'utils/useAccount'
import { formatTokenAmount } from 'utils/web3/helpers'
import { useOpenConnectModal } from 'utils/web3/walletconnect'

type Props = {
  collection: Collection
  className: string
  lowestPrice: string
}

function ReservoirBuyButton({ collection, className, lowestPrice }: Props) {
  const buttonLabel = (
    <>
      Buy for{' '}
      <span className="uppercase">
        {formatTokenAmount({
          amount: lowestPrice,
        })}
      </span>
    </>
  )
  const { openConnectModal } = useOpenConnectModal()
  const { isWalletConnected } = useAccount()
  const onClick = useCallback(() => {
    openConnectModal?.()
  }, [openConnectModal])

  const { isCorrectChain, openState, switchNetworkAndOpen } =
    useOpenCorrectNetwork(collection.contract)

  // if wallet not connected, show fake button that triggers wallet connect
  if (!isWalletConnected) {
    return (
      <Button
        variant="heavy"
        className={className}
        label={buttonLabel}
        onClick={onClick}
      />
    )
  }

  if (!isCorrectChain) {
    return (
      <Button
        className={className}
        variant="heavy"
        label={buttonLabel}
        onClick={switchNetworkAndOpen}
      />
    )
  }

  return (
    <SweepModal
      trigger={
        isCorrectChain && (
          <Button variant="heavy" className={className} label={buttonLabel} />
        )
      }
      collectionId={getAddress(collection.contract)}
      openState={openState}
      feesOnTopFixed={[`${contextTreasuryAddress}:${referrerFee}`]}
    />
  )
}

export default memo(ReservoirBuyButton)
