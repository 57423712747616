import classnames from 'utils/classnames'

export default function TranslucentMaterial({
  children,
  className,
  ...props
}: {
  children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classnames(
        'bg-white/10',
        'ring-1 ring-black dark:ring-0',
        'backdrop-filter backdrop-blur-sm rounded-md',
        'px-5 py-5',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
