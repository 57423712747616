import { useMemo } from 'react'
import {
  QueriesOptions,
  QueryFunctionContext,
  UseQueryResult,
  useQueries,
} from '@tanstack/react-query'
import {
  GetWalletClientResult,
  PrepareSendTransactionResult,
  prepareSendTransaction,
} from '@wagmi/core'
import {
  deserialize,
  serialize,
  useAccount, // eslint-disable-line no-restricted-imports
  useNetwork,
  useWalletClient,
} from 'wagmi'
import { AddressHash, ContractTransaction } from 'utils/api'
import { isAddressHash } from 'utils/web3/helpers'
import { prepareTransaction } from 'utils/web3/transactionHelpers'

function queryKey({
  activeChainId,
  chainId,
  request,
  signerAddress,
}: {
  activeChainId: number | undefined
  chainId: number | undefined
  request: ReturnType<typeof prepareTransaction> | undefined
  signerAddress: AddressHash
}) {
  return [
    {
      entity: 'prepareMintTx',
      activeChainId,
      chainId,
      request:
        request !== undefined
          ? {
              ...request,
              value: serialize(request.value),
            }
          : undefined,
      signerAddress,
    },
  ] as const
}

function queryFn({ walletClient }: { walletClient?: GetWalletClientResult }) {
  return ({
    queryKey: [{ chainId, request }],
  }: QueryFunctionContext<ReturnType<typeof queryKey>>) => {
    if (request === undefined) {
      throw new Error('No request')
    }

    const requestWithValue = {
      ...request,
      value:
        typeof request.value === 'bigint'
          ? request.value
          : deserialize(request.value),
    }

    return prepareSendTransaction({
      chainId,
      walletClient,
      ...requestWithValue,
    })
  }
}

export function usePrepareTransactions({
  transactions,
  referrer,
}: {
  transactions: ContractTransaction[]
  referrer: AddressHash
}): UseQueryResult<PrepareSendTransactionResult, Error>[] {
  const { address } = useAccount()
  const { data: walletClient } = useWalletClient()
  const { chain: activeChain } = useNetwork()

  const txsToPrepare = useMemo(() => {
    // if address is undefined, return an array of undefineds
    if (!isAddressHash(address)) {
      return transactions.map(() => undefined)
    }

    return transactions.map((tx) => prepareTransaction(address, tx, referrer))
  }, [address, referrer, transactions])

  const queries = useMemo<
    QueriesOptions<
      {
        queryKey: ReturnType<typeof queryKey>
        queryFn: ReturnType<typeof queryFn>
        enabled: boolean
      }[]
    >
  >(() => {
    return txsToPrepare.map((request) => ({
      queryKey: queryKey({
        activeChainId: activeChain?.id,
        chainId: undefined,
        request,
        signerAddress: address as AddressHash,
      }),
      queryFn: queryFn({ walletClient }),
      enabled:
        address !== undefined &&
        walletClient !== undefined &&
        request !== undefined,
      cacheTime: 0,
      staleTime: 0,
      keepPreviousData: true,
      retry: 0,
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst',
    }))
  }, [activeChain?.id, address, walletClient, txsToPrepare])

  const results = useQueries<
    {
      queryKey: ReturnType<typeof queryKey>
      queryFn: ReturnType<typeof queryFn>
      enabled: boolean
    }[]
  >({ queries })

  return results as UseQueryResult<PrepareSendTransactionResult, Error>[]
}
