import React, { ReactNode, useEffect, useMemo } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import useResizeObserver from 'use-resize-observer'
import MetaTags from 'components/MetaTags'
import { useProjectMintStatus } from './utils'
import {
  CollectionResponse,
  Contract,
  MintSource,
  ProjectTheme,
  contractMetaImageUrl,
  getAddress,
  getProfileFromArrayIfExists,
  isContractEqual,
  useCollectionResponse,
  useCollectionSettings,
  useIsAuthorizedForContract,
} from 'utils/api'
import { getChainByContract, getContractPath } from 'utils/chains'
import classNames from 'utils/classnames'
import { lazy } from 'utils/lazy'
import {
  CustomizeSettingsButton,
  ProjectSettingsButton,
  ShareLinkButton,
} from './CustomizeButtons'
import MintStatusChip from './MintStatusChip'
import RelatedProjects from './RelatedProjects'
import BasePassTheme, { basePassContract } from './Theme/BaseMintPassTheme'
import DefaultTheme from './Theme/DefaultTheme'
import GridTheme from './Theme/GridTheme'
import MinimalTheme from './Theme/MinimalTheme'
import ZoraPassTheme, { zoraPassContract } from './Theme/ZoraMintPassTheme'
import { ThemeProps } from './Theme/types'
import { useCoverImageStyle } from './hooks'

const Sidebar = lazy({
  loader: () => import('components/Project/ProjectSidebar'),
  Fallback: () => null,
})

type Props = {
  contract: Contract
  initialCollectionResponse?: CollectionResponse
  variant: 'fullPage' | 'modal'
  closeButton?: ReactNode
  className?: string
  source: MintSource
}

function Project({
  contract,
  initialCollectionResponse,
  variant,
  closeButton,
  className,
  source,
}: Props) {
  const chain = getChainByContract(contract)
  const collectionResponse = useCollectionResponse(
    contract,
    initialCollectionResponse,
    {
      refreshInterval: 1000 * 20,
    },
  )

  const collection = collectionResponse?.collection

  const isAuthorized = useIsAuthorizedForContract(contract)

  const mintStatus = useProjectMintStatus(contract)

  const { width: contentWidth, height: contentHeight } = useResizeObserver({
    ref:
      variant === 'fullPage' && typeof document !== 'undefined'
        ? document.body
        : undefined,
  })

  const settings = useCollectionSettings(contract, initialCollectionResponse)

  const coverImageStyle = useCoverImageStyle(
    settings?.banner?.uri,
    settings?.banner?.width ?? undefined,
    settings?.banner?.height ?? undefined,
    contentWidth,
    contentHeight,
  )

  useEffect(() => {
    if (typeof document === 'undefined') return
    if (variant !== 'fullPage') return
    if (coverImageStyle === undefined) return

    const body = document.body

    // add the CSSProperties from `coverImageStyle` to the body element
    Object.assign(body.style, coverImageStyle)

    return () => {
      // remove the CSSProperties from `coverImageStyle` from the body element
      Object.keys(coverImageStyle).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        body.style[key as unknown as any] = ''
      })
    }
  }, [coverImageStyle, variant])

  const { query } = useRouter()
  const metaImageUrl = contractMetaImageUrl({
    contract,
    minterAddress: typeof query.minter === 'string' ? query.minter : undefined,
  })

  const deployerAddress = useMemo(() => {
    const profiles = collectionResponse?.profiles
    if (profiles === undefined) return undefined
    const deployerId = collection?.deployer ?? undefined
    if (deployerId === undefined) return undefined
    return getProfileFromArrayIfExists(deployerId, profiles)?.address.value
  }, [collection?.deployer, collectionResponse?.profiles])

  const { theme, showStatusAndSettings } = useMemo<{
    theme: ProjectTheme
    showStatusAndSettings: boolean
  }>(
    () =>
      isContractEqual(contract, basePassContract) ||
      isContractEqual(contract, zoraPassContract)
        ? {
            theme: 'minimal',
            showStatusAndSettings: false,
          }
        : {
            // won't fallback once settings is valid, just need this to satisfy typescript
            theme: settings?.theme ?? 'default',
            showStatusAndSettings: true,
          },
    [contract, settings?.theme],
  )

  if (collection === undefined || settings === undefined) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="text-2xl font-bold">Loading...</div>
      </div>
    )
  }

  const themeProps: ThemeProps = {
    variant,
    contract,
    collection,
    settings,
    source,
  }

  return (
    <>
      <div className={classNames('flex flex-col items-stretch', className)}>
        <MetaTags
          canonicalUrl={`https://mint.fun${getContractPath(contract)}`}
          title={collection.name}
          description={`Mint ${collection.name} on mint.fun`}
          imageUrl={metaImageUrl}
          largeImage={true}
        />

        <Head>
          {/* see more: https://warpcast.notion.site/Frames-Mint-action-Public-cea0d2249e3e41dbafb2e9ab23107275 */}
          <meta property="eth:nft:collection" content={collection.name} />
          {deployerAddress !== undefined && (
            <meta
              property="eth:nft:creator_address"
              content={deployerAddress}
            />
          )}
          {collection.isFunContract && chain !== undefined && (
            <>
              <meta
                property="fc:frame:button:1:target"
                content={`eip155:${chain?.id}:${getAddress(contract)}`}
              />
              <meta property="fc:frame" content="vNext" />
              <meta property="fc:frame:image" content={collection.imageUrl} />
              <meta property="fc:frame:button:1" content="Mint" />
              <meta property="fc:frame:button:1:action" content="mint" />
            </>
          )}
          {chain !== undefined && (
            <meta property="eth:nft:chain" content={chain.alias} />
          )}
          {collection.contractKind !== undefined && (
            <meta property="eth:nft:schema" content={collection.contractKind} />
          )}
          {collection.imageUrl !== undefined && (
            <meta property="eth:nft:media_url" content={collection.imageUrl} />
          )}
          <meta property="eth:nft:mint_count" content={collection.totalMints} />
        </Head>

        {/* status & settings */}
        {showStatusAndSettings && (
          <div className="flex justify-between items-center w-full h-9 mb-6">
            {mintStatus !== undefined ? (
              <MintStatusChip
                mintStatus={mintStatus}
                mintedOutTime={collection.lastEvent}
              />
            ) : (
              <div />
            )}
            {variant === 'fullPage' && (
              <div className="flex flex-row gap-x-2">
                <ShareLinkButton
                  contract={contract}
                  collectionName={collection.name}
                  isFunContract={collection.isFunContract}
                />
                {isAuthorized && (
                  <>
                    <CustomizeSettingsButton />
                    <ProjectSettingsButton contract={contract} />
                  </>
                )}
              </div>
            )}
            {closeButton}
          </div>
        )}

        {isContractEqual(contract, basePassContract) ? (
          <BasePassTheme {...themeProps} />
        ) : isContractEqual(contract, zoraPassContract) ? (
          <ZoraPassTheme {...themeProps} />
        ) : theme === 'default' ? (
          <DefaultTheme {...themeProps} />
        ) : theme === 'minimal' ? (
          <MinimalTheme {...themeProps} />
        ) : theme === 'grid' ? (
          <GridTheme {...themeProps} />
        ) : (
          // fallback to default theme if the client doesn't know about the theme
          <DefaultTheme {...themeProps} />
        )}

        <RelatedProjects contract={contract} />
      </div>
      <Sidebar key={`${contract}-sidebar`} contract={contract} />
    </>
  )
}

export default React.memo(Project)
