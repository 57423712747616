import { useEffect, useMemo, useState } from 'react'
import { useReferrer } from 'hooks/useReferrer'
import TransactionSectionToggle from 'components/Project/TransactionSectionToggle'
import { collectionIsMintedOut } from 'components/Project/utils'
import { Collection, CollectionSettings, MintSource } from 'utils/api'
import classnames from 'utils/classnames'
import MintingUnavailableButton from './MintingUnavailableButton'
import {
  TransactionVariantList,
  TransactionVariantPlusMinus,
} from './TransactionVariants'
import { TransactionGroup, Variant } from './types'
import { usePrepareTransactions } from './usePrepareTransactions'

type Props = {
  variant: Variant
  groups: TransactionGroup[]
  collection: Collection
  settings: CollectionSettings
  source: MintSource
}

function GroupSelector({
  variant,
  groups,
  collection,
  settings,
  source,
}: Props) {
  const [selectedGroupIndex, selectedGroupIndexSet] = useState(0)
  const selectedGroup: TransactionGroup | undefined = groups[selectedGroupIndex]
  const referrer = useReferrer()

  useEffect(() => {
    if (selectedGroupIndex !== 0 && selectedGroupIndex >= groups.length) {
      selectedGroupIndexSet(0)
    }
  }, [selectedGroupIndex, groups.length])

  const results = usePrepareTransactions({
    transactions: selectedGroup?.transactions ?? [],
    referrer,
  })

  const isMintedOut = useMemo(
    () => collectionIsMintedOut(collection) ?? false,
    [collection],
  )

  const showGroups = !isMintedOut && (variant === 'list' || groups.length > 1)

  return (
    <div
      className={classnames(
        'flex flex-col gap-y-6 text-lg',
        variant === 'list' && 'items-center md:items-start',
        variant === 'plusMinus' && 'items-center w-full',
        variant === 'plusMinus' && !showGroups && 'py-5',
      )}
    >
      {showGroups && groups.length > 0 && (
        <div
          className={classnames(
            'flex gap-x-4',
            variant === 'list' && 'w-full',
            variant === 'plusMinus' && 'items-center',
          )}
        >
          {groups.map((group, index) => (
            <TransactionSectionToggle
              key={index}
              variant={group.isAllowlist ? 'allowlist' : 'public'}
              isSelected={selectedGroupIndex === index}
              onClick={() => selectedGroupIndexSet(index)}
            />
          ))}
        </div>
      )}

      {selectedGroup !== undefined ? (
        <>
          {variant === 'list' && (
            <TransactionVariantList
              transactions={selectedGroup.transactions}
              preparedTransactions={results}
              collection={collection}
              settings={settings}
              source={source}
            />
          )}
          {variant === 'plusMinus' && (
            <TransactionVariantPlusMinus
              transactions={selectedGroup.transactions}
              preparedTransactions={results}
              collection={collection}
              settings={settings}
              source={source}
            />
          )}
        </>
      ) : (
        <MintingUnavailableButton collection={collection} settings={settings} />
      )}
    </div>
  )
}

export default GroupSelector
