import React, { useCallback, useMemo, useState } from 'react'
import Link from 'next/link'
import Avatar from 'components/Avatar'
import { ChainIcon } from 'components/ChainIcon'
import HoverReveal from 'components/HoverReveal'
import HoverTimestamp from 'components/HoverTimestamp'
import {
  Collection,
  CollectionSettings,
  Profile,
  getChainId,
  getProfileFromArrayIfExists,
  useCollectionDetails,
} from 'utils/api'
import { getChainById } from 'utils/chains'
import classNames from 'utils/classnames'
import { maxVisibleMaxSupply } from 'utils/constants'
import { internalCtxFlag, useFeatureFlagEnabled } from 'utils/feature-flags'
import { s } from 'utils/helpers'
import { isValidIsoTimestamp } from 'utils/time'
import { formatTokenAmount, tryBigNumber } from 'utils/web3/helpers'
import CollectionURLs from './CollectionURLs'
import { formatConsolidationPercentText, formatPerWalletText } from './helpers'

type Props = {
  collection: Collection
  profiles: Profile[]
  settings: CollectionSettings
}

function Details({ collection, profiles, settings }: Props) {
  const firstEvent = useMemo(
    () =>
      isValidIsoTimestamp(collection.firstEvent)
        ? new Date(collection.firstEvent)
        : undefined,
    [collection.firstEvent],
  )

  const lastEvent = useMemo(
    () =>
      isValidIsoTimestamp(collection.lastEvent)
        ? new Date(collection.lastEvent)
        : undefined,
    [collection.lastEvent],
  )

  const deployerProfile = useMemo(() => {
    const deployerId = collection.deployer
    if (deployerId === undefined || deployerId === null) return undefined
    return getProfileFromArrayIfExists(deployerId, profiles)
  }, [collection.deployer, profiles])

  const chain = useMemo(() => {
    const chainId = getChainId(collection.contract)
    return getChainById(chainId)
  }, [collection.contract])

  const maxSupplyString = useMemo(() => {
    if (collection.maxSupply === undefined) return undefined
    const maxSupply = tryBigNumber(collection.maxSupply)
    if (maxSupply === undefined || maxSupply >= maxVisibleMaxSupply)
      return undefined
    return `${maxSupply.toLocaleString()} item${s(maxSupply)}`
  }, [collection.maxSupply])

  const { data: detailsResponse } = useCollectionDetails(collection.contract)

  const minterCount = useMemo(
    () => BigInt(detailsResponse?.details.minterCount ?? 0),
    [detailsResponse?.details.minterCount],
  )
  const totalMints = useMemo(
    () => tryBigNumber(collection.totalMints) ?? 0n,
    [collection.totalMints],
  )

  const perWalletText = formatPerWalletText(minterCount, totalMints) ?? ''
  const consolidationPercentText =
    formatConsolidationPercentText(minterCount, totalMints) ?? ''

  const showModerationTools = useFeatureFlagEnabled(internalCtxFlag)

  const [isHoveringFee, isHoveringFeeSet] = useState(false)

  const feeMouseOver = useCallback(() => {
    isHoveringFeeSet(true)
  }, [])

  const feeMouseLeave = useCallback(() => {
    isHoveringFeeSet(false)
  }, [])

  const feeInfo = useMemo(() => {
    if (detailsResponse === undefined) return undefined

    if (isHoveringFee) {
      return {
        label: 'Average gas',
        value: formatTokenAmount({
          amount: detailsResponse.details.avgGas,
          tokenDecimals: 9,
          tokenSymbol: 'gwei',
          truncateAmount: 2,
          showFree: false,
        }),
      }
    }

    return {
      label: 'Total gas',
      value: formatTokenAmount({
        amount: detailsResponse.details.totalTxFees,
        truncateAmount: 2,
        showFree: false,
      }),
    }
  }, [detailsResponse, isHoveringFee])

  const mintSaleValue = useMemo(() => {
    if (detailsResponse === undefined) return undefined

    return formatTokenAmount({
      amount: detailsResponse.details.totalValue,
      truncateAmount: 2,
      showFree: false,
    })
  }, [detailsResponse])

  const hideStats = settings.hideStats ?? false

  return (
    <div
      className={classNames(
        'grid gap-y-4 gap-x-2',
        'grid-cols-contract-details-compact md:grid-cols-contract-details',
        'text-sm md:text-base',
      )}
    >
      {deployerProfile !== undefined && (
        <>
          <div>Created by</div>
          <Link
            href={`/profile/${deployerProfile.slug}`}
            className="flex items-center gap-2 font-bold group"
          >
            <Avatar slug={deployerProfile.slug} size={24} />
            <span className="group-hover:underline truncate">
              {deployerProfile.name}
            </span>
          </Link>
        </>
      )}

      {chain !== undefined && (
        <>
          <div>Chain</div>
          <div className="flex items-center gap-2 font-bold">
            <ChainIcon chainId={chain.id} className="h-4 w-4" />
            <span className="normal-case">{chain.name}</span>
          </div>
        </>
      )}

      {!hideStats && (
        <>
          {firstEvent !== undefined && (
            <>
              <div>First mint</div>
              <HoverTimestamp timestamp={firstEvent} />
            </>
          )}

          {lastEvent !== undefined && (
            <>
              <div>Last mint</div>
              <HoverTimestamp timestamp={lastEvent} />
            </>
          )}

          {minterCount > 0n && (
            <>
              <div>Unique minters</div>
              <div>
                <HoverReveal
                  visibleContent={() =>
                    `${minterCount.toLocaleString()} ${consolidationPercentText}`
                  }
                  hiddenContent={() =>
                    `${minterCount.toLocaleString()} ${perWalletText}`
                  }
                />
              </div>
            </>
          )}

          <div>Total minted</div>
          <div>
            {totalMints.toLocaleString()} item
            {s(totalMints)}
          </div>

          {maxSupplyString !== undefined && (
            <>
              <div>Max supply</div>
              <div>{maxSupplyString}</div>
            </>
          )}

          {mintSaleValue !== undefined && (
            <>
              <div>Mint sales</div>
              <div className="normal-case">{mintSaleValue}</div>
            </>
          )}

          {feeInfo !== undefined && (
            <>
              <div onMouseOver={feeMouseOver} onMouseLeave={feeMouseLeave}>
                {feeInfo.label}
              </div>

              <div
                onMouseOver={feeMouseOver}
                onMouseLeave={feeMouseLeave}
                className="uppercase"
              >
                {feeInfo.value}
              </div>
            </>
          )}

          {collection.contractKind !== undefined && (
            <>
              <div>Kind</div>
              <div className="uppercase">{collection.contractKind}</div>
            </>
          )}

          {detailsResponse !== undefined &&
            detailsResponse.details.metadataSource !== undefined && (
              <>
                <div>Media source</div>
                <div>{detailsResponse.details.mediaSource}</div>
                <div>Metadata source</div>
                <div>{detailsResponse.details.metadataSource}</div>
              </>
            )}
        </>
      )}

      <CollectionURLs
        contract={collection.contract}
        additionalUrls={detailsResponse?.details.additionalUrls}
      />

      {showModerationTools && (
        <>
          <div>Moderation</div>
          <a
            href={`https://contextapp.retool.com/apps/7480cac2-c196-11ec-902a-ebd12a71d4b6/Collection%20Moderation#contractAddress=${collection.contract}`}
            target="_blank"
            rel="noreferrer"
            className="font-bold group"
          >
            <span className="group-hover:underline truncate">Retool</span>
          </a>
        </>
      )}
    </div>
  )
}

export default React.memo(Details)
