import { useMemo } from 'react'
import Link from 'next/link'
import { Collection } from 'utils/api'
import { getContractPath } from 'utils/chains'
import { textSecondary } from 'utils/theme'
import { formatTimestamp, isValidIsoTimestamp } from 'utils/time'
import CollectionAvatar from './CollectionAvatar'
import ProjectSubtitleText, {
  useWillSubtitleRender,
} from './ProjectSubtitleText'

export const ProjectItem = ({ collection }: { collection: Collection }) => {
  const timestamp = useMemo(
    () =>
      isValidIsoTimestamp(collection.firstEvent)
        ? formatTimestamp(new Date(collection.firstEvent), 'en_truncated')
        : undefined,
    [collection.firstEvent],
  )

  const subtitleWillRender = useWillSubtitleRender(collection)

  return (
    <Link
      href={getContractPath(collection.contract)}
      className="flex flex-col gap-y-3"
    >
      <CollectionAvatar
        contract={collection.contract}
        imageSize={300}
        className="hidden md:flex"
      />
      <CollectionAvatar
        contract={collection.contract}
        imageSize={380}
        className="hidden xs:flex md:hidden"
      />
      <CollectionAvatar
        contract={collection.contract}
        imageSize={460}
        className="flex xs:hidden"
      />
      <div className="flex flex-col">
        <span className="font-bold text-base xs:text-sm normal-case">
          {collection.name}
        </span>
        {(subtitleWillRender || isValidIsoTimestamp(collection.firstEvent)) && (
          <div className="text-sm xs:text-xs">
            <ProjectSubtitleText
              collection={collection}
              includeCountdown={false}
            />
            {timestamp !== undefined && (
              <>
                {subtitleWillRender && ' • '}
                <span className={textSecondary}>{timestamp}</span>
              </>
            )}
          </div>
        )}
      </div>
    </Link>
  )
}
