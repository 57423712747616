import { useCallback, useEffect, useMemo, useState } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { PrepareSendTransactionResult } from '@wagmi/core'
import { Button } from 'components/Button'
import TransactionButton from 'components/TransactionButton'
import MinusIcon from 'components/icons/MinusIcon'
import PlusIcon from 'components/icons/PlusIcon'
import {
  getTransactionsForFiltered,
  themeToButtonClasses,
  transactionKey,
} from './utils'
import {
  AddressHash,
  Collection,
  CollectionSettings,
  ContractTransaction,
  MintSource,
  getChainId,
} from 'utils/api'
import classnames from 'utils/classnames'
import { s } from 'utils/helpers'
import { useAccount } from 'utils/useAccount'
import { shouldIncludeMintButton } from 'utils/web3/helpers'
import MintingUnavailableButton from './MintingUnavailableButton'
import { TransactionWithPreparedResult } from './types'

const maxTransactionsToShowCollapsed = 3

type CommonProps = {
  transactions: ContractTransaction[]
  preparedTransactions: UseQueryResult<PrepareSendTransactionResult, Error>[]
  collection: Collection
  settings: CollectionSettings
  source: MintSource
}

export function TransactionVariantList({
  transactions,
  preparedTransactions,
  collection,
  settings,
  source,
}: CommonProps) {
  const allTransactions = useMemo<TransactionWithPreparedResult[]>(
    () =>
      transactions.map((transaction, index) => ({
        transaction,
        preparedResult: preparedTransactions[index],
      })),
    [transactions, preparedTransactions],
  )

  const collapsedTransactions = useMemo(
    () =>
      getTransactionsForFiltered(
        allTransactions,
        maxTransactionsToShowCollapsed,
      ),
    [allTransactions],
  )

  const moreCount = transactions.length - collapsedTransactions.length
  const [viewMoreTransactions, viewMoreTransactionsSet] = useState(false)
  const showViewMore = !viewMoreTransactions && moreCount > 0

  const handleShowMoreOptions = useCallback(
    () => viewMoreTransactionsSet(true),
    [],
  )

  const transactionsWithPrepared = useMemo<
    TransactionWithPreparedResult[]
  >(() => {
    if (viewMoreTransactions) {
      return allTransactions
    }

    return collapsedTransactions
  }, [allTransactions, collapsedTransactions, viewMoreTransactions])

  return (
    <>
      {transactionsWithPrepared.length === 0 && (
        <MintingUnavailableButton collection={collection} settings={settings} />
      )}
      {transactionsWithPrepared.map(({ transaction, preparedResult }) => (
        <TransactionButton
          key={transactionKey(transaction)}
          transaction={transaction}
          preparedTransaction={preparedResult}
          collectionContract={collection.contract}
          collectionName={collection.name}
          collectionFlags={collection.flags}
          isFunContract={collection.isFunContract}
          buttonClassName={themeToButtonClasses[settings.theme]}
          buttonSettings={settings.mintButton}
          source={source}
        />
      ))}
      {showViewMore && moreCount > 0 && (
        <Button
          label={`Show ${moreCount} more option${s(moreCount)}`}
          className={classnames('w-full md:w-96')}
          variant="outline"
          onClick={handleShowMoreOptions}
        />
      )}
    </>
  )
}

export function TransactionVariantPlusMinus({
  transactions,
  preparedTransactions,
  collection,
  settings,
  source,
}: CommonProps) {
  const [selectedIndex, selectedIndexSet] = useState(0)

  const targetChainId = getChainId(collection.contract)
  const { address, chainId } = useAccount()

  const isWrongChain = targetChainId !== chainId

  const allTransactions = useMemo<TransactionWithPreparedResult[]>(() => {
    const result: TransactionWithPreparedResult[] = []
    const seenMintableForCombo = new Set<string>()

    for (let i = 0; i < transactions.length; i++) {
      const transaction = transactions[i]
      const preparedResult = preparedTransactions[i]

      const key = `${transaction.ethValue}-${transaction.nftCount}`

      if (seenMintableForCombo.has(key)) {
        continue
      }

      if (
        shouldIncludeMintButton(
          isWrongChain,
          transaction,
          address as AddressHash,
          preparedResult.error,
        )
      ) {
        seenMintableForCombo.add(key)
        result.push({
          transaction,
          preparedResult,
        })
      }
    }

    return result
  }, [transactions, preparedTransactions, address, isWrongChain])

  const nextMintOption = useCallback(() => {
    selectedIndexSet((prev) => (prev + 1) % allTransactions.length)
  }, [allTransactions.length])

  const prevMintOption = useCallback(() => {
    selectedIndexSet(
      (prev) => (prev - 1 + allTransactions.length) % allTransactions.length,
    )
  }, [allTransactions.length])

  useEffect(() => {
    if (selectedIndex >= allTransactions.length) {
      selectedIndexSet(0)
    }
  }, [allTransactions.length, selectedIndex])

  return (
    <>
      {allTransactions.length > 0 && selectedIndex < allTransactions.length && (
        <TransactionButton
          key={transactionKey(allTransactions[selectedIndex].transaction)}
          transaction={allTransactions[selectedIndex].transaction}
          preparedTransaction={allTransactions[selectedIndex].preparedResult}
          buttonClassName={themeToButtonClasses[settings.theme]}
          collectionContract={collection.contract}
          collectionName={collection.name}
          collectionFlags={collection.flags}
          isFunContract={collection.isFunContract}
          buttonSettings={settings.mintButton}
          source={source}
        />
      )}
      {allTransactions.length === 0 && (
        <MintingUnavailableButton collection={collection} settings={settings} />
      )}

      {allTransactions.length > 1 && (
        <div className="flex gap-x-3">
          <button
            disabled={selectedIndex === 0}
            onClick={prevMintOption}
            className={classnames(
              'flex items-center justify-center w-12 h-12 rounded-full',
              'dark:hover:bg-white/10 hover:bg-black/5',
              'dark:hover:disabled:bg-transparent hover:disabled:bg-transparent disabled:opacity-50',
            )}
          >
            <MinusIcon />
          </button>
          <button
            disabled={selectedIndex === allTransactions.length - 1}
            onClick={nextMintOption}
            className={classnames(
              'flex items-center justify-center w-12 h-12 rounded-full',
              'dark:hover:bg-white/10 hover:bg-black/5',
              'dark:hover:disabled:bg-transparent hover:disabled:bg-transparent disabled:opacity-50',
            )}
          >
            <PlusIcon />
          </button>
        </div>
      )}
    </>
  )
}
