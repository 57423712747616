import React from 'react'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import PendingTransactionsChip from 'components/PendingTransactionsChip'
import ProjectSubtitleText from 'components/ProjectSubtitleText'
import { ProjectWarningLabelBadge } from 'components/ProjectWarningLabel'
import {
  Collection,
  PendingTransactionsResponse,
  ProjectTheme,
  contractPendingTransactionsEndpoint,
  useAPIResponse,
} from 'utils/api'
import classnames from 'utils/classnames'
import { internalCtxFlag, useFeatureFlagEnabled } from 'utils/feature-flags'
import { lazy } from 'utils/lazy'

const MintHeatMeter = lazy({
  loader: () => import('components/MintHeatMeter'),
  Fallback: () => null,
})

type Props = {
  collection: Collection
  theme: ProjectTheme
}

function Heading({ collection, theme }: Props) {
  const { data: pendingTxData } = useAPIResponse<PendingTransactionsResponse>(
    contractPendingTransactionsEndpoint({
      contract: collection.contract,
    }),
    undefined,
    { refreshInterval: 5000 },
  )

  const showMintHeat = useFeatureFlagEnabled(internalCtxFlag)

  return (
    <div
      className={classnames(
        'flex flex-col gap-1',
        theme === 'default' && 'self-start',
        (theme === 'minimal' || theme === 'grid') && 'items-center pb-5',
      )}
    >
      <div
        className={classnames(
          'flex items-baseline space-x-2 font-bold',
          'line-clamp-2',
          'text-3xl',
          theme === 'default' && 'md:text-4xl',
          (theme === 'minimal' || theme === 'grid') &&
            'md:text-[2.5rem] md:leading-[3.75rem] max-w-[640px] text-center',
        )}
      >
        {collection.flags !== undefined && (
          <ProjectWarningLabelBadge flags={collection.flags} />
        )}

        <span className="normal-case">{collection.name}</span>
      </div>
      <LayoutGroup>
        <div
          className={classnames(
            'flex items-center gap-x-3 md:gap-x-5',
            'text-base md:text-xl',
            'md:flex-wrap-reverse',
            (theme === 'minimal' || theme === 'grid') && 'pt-2',
          )}
        >
          <AnimatePresence>
            {pendingTxData !== undefined && pendingTxData.total > 0 && (
              <motion.div
                layout="position"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{
                  duration: 0.2,
                  layout: { duration: 0.2 },
                }}
              >
                <PendingTransactionsChip
                  total={pendingTxData.total}
                  wallets={pendingTxData.wallets}
                />
              </motion.div>
            )}
          </AnimatePresence>
          {showMintHeat && collection.mintHeat !== undefined && (
            <motion.div
              layout="position"
              transition={{
                layout: { duration: 0.2 },
              }}
            >
              <MintHeatMeter
                heat={collection.mintHeat}
                className="hidden md:flex"
              />
            </motion.div>
          )}
          <motion.div
            layout="position"
            transition={{
              layout: { duration: 0.2 },
            }}
          >
            <ProjectSubtitleText
              collection={collection}
              mintsLastHour={pendingTxData?.lastHour}
            />
          </motion.div>
        </div>
      </LayoutGroup>
    </div>
  )
}

export default React.memo(Heading)
