import React from 'react'
import DetailsSection from 'components/Project/DetailsSection'
import Heading from 'components/Project/Heading'
import ProjectMediaCarousel from 'components/ProjectMediaCarousel'
import TransactionList from 'components/TransactionList'
import classNames from 'utils/classnames'
import CustomizeCTABanner from '../CustomizeCTABanner'
import DescriptionSection from '../DescriptionSection'
import RecentMints from '../RecentMints'
import { ThemeProps } from './types'

function MinimalTheme({
  variant,
  contract,
  collection,
  settings,
  source,
}: ThemeProps) {
  return (
    <div className="flex flex-col items-center gap-y-6">
      <Heading collection={collection} theme="minimal" />
      <ProjectMediaCarousel
        settings={settings}
        className={classNames('w-full max-w-[440px]')}
      />
      <TransactionList
        collection={collection}
        settings={settings}
        source={source}
      />
      <CustomizeCTABanner contract={contract} variant={variant} />

      {settings.hideRecentActivity !== true && (
        <RecentMints contract={contract} className="w-full pb-5 pt-3" />
      )}

      <DescriptionSection settings={settings} />
      <DetailsSection
        contract={contract}
        settings={settings}
        className="self-stretch"
      />
    </div>
  )
}

export default React.memo(MinimalTheme)
