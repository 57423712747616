import { useCallback, useEffect } from 'react'
import { Button, ButtonLink } from 'components/Button'
import CustomizeWand from 'components/icons/CustomizeWand'
import ProjectSettingsCog from 'components/icons/ProjectSettingsCog'
import { Contract, useIsAuthorizedForContract } from 'utils/api'
import { getContractPath } from 'utils/chains'
import { useAccountOrLocalCheckpoint } from 'utils/checkpoints'
import classnames from 'utils/classnames'
import { createCustomToast, dismissToast } from 'utils/toasts'
import { useSidebarOpen, useSidebarToggle } from 'utils/useLayout'

type Props = {
  contract: Contract
  variant: 'fullPage' | 'modal'
}

export default function CustomizeCTABanner({ contract, variant }: Props) {
  const toastId = 'customize-cta-banner'
  const [seenBanner, markBannerAsSeen] = useAccountOrLocalCheckpoint(
    'customize-cta-banner',
    false,
  )
  const isAuthorized = useIsAuthorizedForContract(contract)

  const sidebarOpen = useSidebarOpen()
  const toggleSidebar = useSidebarToggle()

  const handleCustomizeClick = useCallback(() => {
    if (!sidebarOpen) {
      toggleSidebar()
    }
    markBannerAsSeen()
    dismissToast(toastId)
  }, [markBannerAsSeen, sidebarOpen, toggleSidebar])

  // dismiss on unmount
  useEffect(() => {
    return () => {
      dismissToast(toastId)
    }
  }, [])

  useEffect(() => {
    if (!isAuthorized || seenBanner || variant !== 'fullPage') {
      return
    }

    createCustomToast(
      <div className={classnames('flex flex-col gap-y-5 max-w-lg relative')}>
        <h2 className="text-base">
          <span className="font-bold">You created this project!</span>
          <p className="font-normal">
            Customize the theme of your project page or add mint options.
          </p>
        </h2>
        <div
          className={classnames(
            'grid gap-3',
            'grid-rows-2 grid-cols-none',
            'sm:grid-cols-2 sm:grid-rows-none',
          )}
        >
          <Button
            onClick={handleCustomizeClick}
            className={classnames(
              'bg-green dark:bg-green hover:bg-green/80 dark:hover:bg-green/80',
              'shadow-[0px_0px_8px_4px] shadow-green/50',
            )}
            labelClassName="text-black"
            label="Customize"
            icon={<CustomizeWand />}
          />
          <ButtonLink
            variant="raised"
            href={`${getContractPath(contract)}/settings/mint-options`}
            label="Add mint options"
            icon={<ProjectSettingsCog />}
          />
        </div>
      </div>,
      Infinity,
      toastId,
      () => markBannerAsSeen(),
    )
  }, [
    contract,
    handleCustomizeClick,
    isAuthorized,
    markBannerAsSeen,
    seenBanner,
    variant,
  ])

  return null
}
