import { SVGProps } from 'react'
import { ChainId } from 'utils/api'
import { getChainById } from 'utils/chains'
import { assertUnreachable } from 'utils/typescript'
import {
  BaseLogo,
  BaseLogoMono,
  EthereumLogo,
  OptimismLogo,
  OptimismLogoMono,
  ZoraLogo,
  ZoraLogoMono,
} from './icons/Chains'

type ChainIconProps = SVGProps<SVGSVGElement> & {
  chainId: ChainId
  variant?: 'mono'
}

export const ChainIcon = ({ chainId, variant, ...props }: ChainIconProps) => {
  const chainName = getChainById(chainId)?.name
  switch (chainId) {
    case 1:
    case 5:
      // use same logo for both mono and normal
      return variant === 'mono' ? (
        <span className="text-[#404040]">
          <EthereumLogo title={chainName} {...props} />
        </span>
      ) : (
        <EthereumLogo title={chainName} {...props} />
      )
    case 10:
    case 420:
      return variant === 'mono' ? (
        <OptimismLogoMono title={chainName} {...props} />
      ) : (
        <OptimismLogo title={chainName} {...props} />
      )
    case 8453:
    case 84531:
      return variant === 'mono' ? (
        <BaseLogoMono title={chainName} {...props} />
      ) : (
        <BaseLogo title={chainName} {...props} />
      )
    case 7777777:
      return variant === 'mono' ? (
        <ZoraLogoMono title={chainName} {...props} />
      ) : (
        <ZoraLogo title={chainName} {...props} />
      )
    default:
      return assertUnreachable(chainId)
  }
}
