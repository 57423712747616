import React from 'react'
import classNames from 'utils/classnames'
import { background } from 'utils/theme'

const Section = React.memo(
  ({ title, children }: { title: string; children: React.ReactNode }) => (
    <div className="flex flex-col gap-4 md:gap-6">
      <div className="text-xl font-bold">{title}</div>
      <div>{children}</div>
    </div>
  ),
)

Section.displayName = 'Section'

const SectionContainer = React.memo(
  ({
    children,
    className,
  }: {
    children: React.ReactNode
    className?: string
  }) => (
    <div
      className={classNames('py-3 md:py-6 px-4 md:px-8', background, className)}
    >
      {children}
    </div>
  ),
)

SectionContainer.displayName = 'SectionContainer'

export { Section, SectionContainer }
