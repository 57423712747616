import { ProjectItem } from 'components/ProjectItem'
import {
  Collection,
  Contract,
  relatedProjectsEndpoint,
  useAPIResponse,
} from 'utils/api'
import classnames from 'utils/classnames'
import { internalCtxFlag, useFeatureFlagEnabled } from 'utils/feature-flags'

type Props = {
  contract: Contract
}

type RelatedProjectsResponse = {
  similarMinters: Collection[]
}

export default function RelatedProjects({ contract }: Props) {
  const showRelatedProjects = useFeatureFlagEnabled(internalCtxFlag)

  const { data } = useAPIResponse<RelatedProjectsResponse>(
    relatedProjectsEndpoint({ contract }),
    undefined,
    { skipFetching: !showRelatedProjects },
  )

  if (!showRelatedProjects) {
    return null
  }

  if (data === undefined || data.similarMinters.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col gap-y-8 pt-6">
      <RelatedProjectsRow
        title="Some projects you might like"
        projects={data.similarMinters.slice(0, 4)}
      />
    </div>
  )
}

function RelatedProjectsRow({
  title,
  projects,
}: {
  title: string
  projects: Collection[]
}) {
  if (projects.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col items-start">
      <div
        className={classnames(
          'inline-flex items-center gap-x-2',
          'rounded-xl bg-neutral-300 dark:bg-neutral-700',
          'px-3 h-9 mb-2 md:mb-8',
        )}
      >
        {title}
      </div>

      <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-y-6 xs:gap-4 w-full">
        {projects.map((collection) => (
          <ProjectItem key={collection.contract} collection={collection} />
        ))}
      </div>
    </div>
  )
}
