import { CSSProperties, useMemo } from 'react'
import { useTheme } from 'next-themes'

export const useCoverImageStyle = (
  headerImageUrl: string | undefined,
  headerImageWidth: number | undefined,
  headerImageHeight: number | undefined,
  contentWidth: number | undefined,
  contentHeight: number | undefined,
): CSSProperties | undefined => {
  const { theme } = useTheme()

  const wrapperStyle = useMemo<CSSProperties | undefined>(() => {
    if (headerImageUrl === undefined) {
      return undefined
    }

    if (
      contentWidth === undefined ||
      contentHeight === undefined ||
      headerImageWidth === undefined ||
      headerImageHeight === undefined
    ) {
      return undefined
    }

    // calculate the % of the div we need to have the gradient over to
    // make sure it covers the whole image
    const heightScale = headerImageWidth / contentWidth
    const gradientPercent = headerImageHeight / heightScale / contentHeight
    let fullPercentage = gradientPercent * 100
    // ensure that the percentage is no more than 600px high
    fullPercentage = Math.min(fullPercentage, (600 / contentHeight) * 100)

    const backgroundColor = theme === 'dark' ? '25, 25, 25' : '255, 255, 255'
    const startColor = backgroundColor
    const endColor = backgroundColor

    const gradientBackground = `linear-gradient(180deg, rgba(${startColor}, 0) -${
      fullPercentage * 4
    }%, rgba(${endColor}, 1) ${fullPercentage}%)`

    return {
      backgroundImage: `${gradientBackground}, url(${headerImageUrl})`,
      backgroundSize: 'auto, 100% auto',
      backgroundPosition: 'top, top',
      backgroundRepeat: 'no-repeat, no-repeat',
    }
  }, [
    headerImageUrl,
    contentWidth,
    contentHeight,
    headerImageWidth,
    headerImageHeight,
    theme,
  ])

  return wrapperStyle
}
