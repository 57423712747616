import React, { useEffect, useMemo } from 'react'
import { Spinner } from 'components/icons/Spinner'
import {
  Collection,
  CollectionMinter,
  Contract,
  Profile,
  contractMintCountEndpoint,
  useAPIResponse,
  useCollectionDetails,
} from 'utils/api'
import classNames from 'utils/classnames'
import { mutateRead } from 'utils/swr'
import { useAccount } from 'utils/useAccount'
import MinterRow from './MinterRow'

export const usePrefetchMintCount = (collectionContract: Contract) => {
  const { isLoggedIn, address } = useAccount()
  useEffect(() => {
    if (isLoggedIn && address !== undefined) {
      mutateRead(
        contractMintCountEndpoint({ contract: collectionContract, address }),
      )
    }
  }, [address, collectionContract, isLoggedIn])
}

type Props = {
  collection: Collection
}

function TopMinters({ collection }: Props) {
  const { isLoggedIn, account, address } = useAccount()

  // this is prefetched as soon as we have the contract in the parent component
  const { data: mintCountResponse } = useAPIResponse<{ count: `${bigint}` }>(
    contractMintCountEndpoint({ contract: collection.contract, address }),
    undefined,
    { skipFetching: !isLoggedIn || address === undefined },
  )

  const mintCount = mintCountResponse?.count

  const { data: detailsResponse } = useCollectionDetails(collection.contract)

  const [minters, minterProfiles] = useMemo<
    [CollectionMinter[], Profile[]] | [undefined, undefined]
  >(() => {
    if (detailsResponse === undefined) {
      return [undefined, undefined]
    }

    const {
      details: { topMinters: minters },
      profiles: minterProfiles,
    } = detailsResponse
    const maxMintersToShow = 8
    const visibleMinters = minters.slice(0, maxMintersToShow)

    if (mintCount === undefined || BigInt(mintCount) === 0n || !account) {
      return [visibleMinters, minterProfiles]
    }

    const myMinter = {
      minter: account.profile.id,
      count: mintCount,
    }

    // Check if the current user is already in the list
    const isCurrentUserInVisibleList = visibleMinters.some(
      (minter) => minter.minter === account.profile.id,
    )

    if (isCurrentUserInVisibleList) {
      return [visibleMinters, minterProfiles]
    }

    // Find index to insert current user
    const indexToInsert = visibleMinters.findIndex(
      (minter) => BigInt(minter.count) <= BigInt(mintCount),
    )

    // Insert or append the current user minter
    const updatedMinters =
      indexToInsert === -1
        ? [...visibleMinters.slice(0, -1), myMinter]
        : [
            ...visibleMinters.slice(0, indexToInsert),
            myMinter,
            ...visibleMinters.slice(indexToInsert),
          ]

    return [
      updatedMinters.slice(0, maxMintersToShow),
      [...minterProfiles, account.profile],
    ]
  }, [account, detailsResponse, mintCount])

  if (minters === undefined || minterProfiles === undefined) {
    return (
      <div className="w-full flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <ul
      className={classNames(
        'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-3',
        'text-sm md:text-base truncate',
      )}
    >
      {minters.map(({ minter, count }) => (
        <MinterRow
          key={minter}
          minter={minter}
          count={count.toString()}
          profiles={minterProfiles}
          className={minter === account?.profile.id ? 'font-bold' : ''}
        />
      ))}
    </ul>
  )
}

export default React.memo(TopMinters)
