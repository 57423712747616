import React, { useMemo } from 'react'
import { CollectionURL, Contract } from 'utils/api'
import { getCollectionURLs } from 'utils/chains'

type Props = {
  contract: Contract
  additionalUrls?: CollectionURL[]
}

const CollectionURLs = ({ contract, additionalUrls }: Props) => {
  const contractLinks = useMemo(
    () => getCollectionURLs(contract).concat(additionalUrls ?? []),
    [additionalUrls, contract],
  )
  const urlsWithAttribution = useMemo<CollectionURL[]>(
    () =>
      contractLinks.map((link) => ({
        label: link.label,
        url: withUtmSource(link.url),
      })),
    [contractLinks],
  )

  if (urlsWithAttribution.length === 0) {
    return null
  }

  return (
    <>
      <div>Project links</div>
      <div>
        {urlsWithAttribution.map(({ url, label }, i) => (
          <span
            key={`${url}-${i}-${String(i === urlsWithAttribution.length - 1)}`}
          >
            <a
              href={url}
              className="font-bold hover:underline normal-case"
              target="_blank"
              rel="noreferrer"
            >
              {label}
            </a>
            {i < urlsWithAttribution.length - 1 && ', '}
          </span>
        ))}
      </div>
    </>
  )
}

export default React.memo(CollectionURLs)

const withUtmSource = (url: string) => {
  // add `utm_source=mint.fun` to the query string, or replace it if it already exists
  try {
    const urlObj = new URL(url)
    urlObj.searchParams.set('utm_source', 'mint.fun')
    return urlObj.toString()
  } catch (e) {
    return url
  }
}
