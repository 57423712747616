import { useCallback, useEffect, useMemo } from 'react'
import { useLocalStorage } from '@buzuosheng/use-localstorage'
import Link from 'next/link'
import { Button, ButtonLink } from 'components/Button'
import { collectionIsMintedOut } from 'components/Project/utils'
import { TransactionButtonUnstyled } from 'components/TransactionButton'
import ProjectSettingsCog from 'components/icons/ProjectSettingsCog'
import { themeToButtonClasses } from './utils'
import { Collection, CollectionSettings } from 'utils/api'
import { getContractPath } from 'utils/chains'
import classnames from 'utils/classnames'
import { createCustomToast, dismissToast } from 'utils/toasts'
import { useSidebarOpen } from 'utils/useLayout'
import useLowestPrice from 'utils/useLowestPrice'
import ReservoirBuyButton from './ReservoirBuyButton'

type Props = {
  collection: Collection
  settings: CollectionSettings
}

export default function MintingUnavailableButton({
  collection,
  settings,
}: Props) {
  const { isLoading: loadingListings, lowestPrice } = useLowestPrice(collection)

  const isMintedOut = useMemo(
    () => collectionIsMintedOut(collection) ?? false,
    [collection],
  )

  const sidebarOpen = useSidebarOpen()
  const [dismissedExplainer = false, dismissedExplainerSet] =
    useLocalStorage<boolean>(
      `dismissed-button-explainer-${collection.contract}`,
      {
        prefix: 'mint.fun',
        age: '10y',
        initialValue: false,
      },
    )

  const handleDismiss = useCallback(() => {
    dismissedExplainerSet(true)
    dismissToast('custom-button-explainer')
  }, [dismissedExplainerSet])

  useEffect(() => {
    if (dismissedExplainer || !sidebarOpen) {
      return
    }

    createCustomToast(
      <div className="flex flex-col gap-y-4">
        <p>
          Heads up — You’re previewing an example button, since your mint
          options aren’t available. Add mint options in{' '}
          <Link
            href={`${getContractPath(
              collection.contract,
            )}/settings/mint-options`}
            className="font-bold underline"
            onClick={handleDismiss}
          >
            Settings
          </Link>
          .
        </p>
        <div
          className={classnames(
            'grid gap-3',
            'grid-rows-2 grid-cols-none',
            'sm:grid-cols-2 sm:grid-rows-none',
          )}
        >
          <Button
            variant="raised"
            label="I’ll do it later"
            onClick={handleDismiss}
          />
          <ButtonLink
            variant="raised"
            href={`${getContractPath(
              collection.contract,
            )}/settings/mint-options`}
            onClick={handleDismiss}
            label="Add mint options"
            icon={<ProjectSettingsCog />}
          />
        </div>
      </div>,
      Infinity,
      'custom-button-explainer',
      handleDismiss,
    )
  }, [
    collection.contract,
    dismissedExplainer,
    dismissedExplainerSet,
    handleDismiss,
    sidebarOpen,
  ])

  const { theme, mintButton } = settings

  if (sidebarOpen) {
    return (
      <div className={classnames('flex flex-col gap-y-2')}>
        <TransactionButtonUnstyled
          buttonSettings={mintButton}
          label={
            <>
              Mint for 0.123 <span className="uppercase">ETH</span>
            </>
          }
          ariaLabel="Mint for 0.123 ETH"
          className={themeToButtonClasses[theme]}
        />
      </div>
    )
  }

  const mintOrBuyClasses = classnames(
    'w-full md:w-96',
    themeToButtonClasses[theme],
  )

  // show empty loading button while we wait for reservoir listings
  if (loadingListings) {
    return <Button pending className={mintOrBuyClasses} disabled label="" />
  }

  // found a lowest priced listing, so show the buy button
  if (lowestPrice !== undefined) {
    return (
      <ReservoirBuyButton
        className={mintOrBuyClasses}
        collection={collection}
        lowestPrice={lowestPrice}
      />
    )
  }

  // no listings found and we've determined totalMints >= totalSupply
  if (isMintedOut) {
    return (
      <Button
        variant="outline"
        className={mintOrBuyClasses}
        disabled
        label="Minted out"
      />
    )
  }

  // otherwise show mint unavailable button
  return (
    <TransactionButtonUnstyled
      disabled
      buttonSettings={mintButton}
      label="Minting not available"
      className={themeToButtonClasses[theme]}
    />
  )
}
