import { useMemo } from 'react'
import { useListings } from '@reservoir0x/reservoir-kit-ui'
import { Collection, getAddress } from 'utils/api'
import { getChainByContract } from './chains'

export default function useLowestPrice(collection: Collection) {
  const collectionChain = getChainByContract(collection.contract)
  const { data, error, isLoading } = useListings(
    {
      contracts: [getAddress(collection.contract)],
      sortBy: 'price',
      status: 'active',
    },
    {},
    collectionChain?.supportsSecondaryMarket ?? false,
    collectionChain?.id,
  )

  return useMemo(() => {
    if (isLoading) {
      return { isLoading: true, lowestPrice: undefined }
    }

    if (error !== undefined || data.length === 0) {
      return { isLoading: false, lowestPrice: undefined }
    }

    return {
      isLoading: false,
      lowestPrice: data[0].price?.amount?.raw,
    }
  }, [data, error, isLoading])
}
