import React from 'react'
import DetailsSection from 'components/Project/DetailsSection'
import Heading from 'components/Project/Heading'
import RecentMints from 'components/Project/RecentMints'
import ProjectMediaCarousel from 'components/ProjectMediaCarousel'
import TransactionList from 'components/TransactionList'
import classNames from 'utils/classnames'
import CustomCTALinks from '../CustomCTALinks'
import CustomizeCTABanner from '../CustomizeCTABanner'
import DescriptionSection from '../DescriptionSection'
import { ThemeProps } from './types'

function DefaultTheme({
  variant,
  contract,
  collection,
  settings,
  source,
}: ThemeProps) {
  return (
    <div className="flex flex-col items-stretch">
      <div
        className={classNames(
          'flex flex-col-reverse md:flex-row items-start justify-between',
          'gap-x-4 gap-y-6',
          'mb-16',
        )}
      >
        <div className="flex flex-col items-stretch w-full gap-y-6">
          <Heading collection={collection} theme="default" />
          <TransactionList
            collection={collection}
            settings={settings}
            source={source}
          />
          <CustomCTALinks contract={contract} />
          <CustomizeCTABanner contract={contract} variant={variant} />
        </div>

        <ProjectMediaCarousel
          settings={settings}
          className={classNames(
            'self-center md:self-start',
            'w-full max-w-[470px]',
            'md:h-[330px] md:w-[330px]',
            'lg:h-[470px] lg:w-[470px]',
          )}
        />
      </div>

      <div className="flex flex-col items-stretch gap-y-14">
        <RecentMints contract={contract} />
        <DescriptionSection settings={settings} />
        <DetailsSection contract={contract} settings={settings} />
      </div>
    </div>
  )
}

export default React.memo(DefaultTheme)
