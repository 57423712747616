import React from 'react'
import Link from 'next/link'
import Avatar from 'components/Avatar'
import { Profile, ProfileId, getProfileFromArrayIfExists } from 'utils/api'
import classNames from 'utils/classnames'
import { s } from 'utils/helpers'

type Props = {
  minter: ProfileId
  count: string
  profiles: Profile[]
  className?: string
}

function MinterRow({ minter, count, profiles, className }: Props) {
  const profile = getProfileFromArrayIfExists(minter, profiles)

  if (profile === undefined) {
    // rather not show than error here
    return null
  }

  return (
    <li className={classNames('group', className)}>
      <Link
        href={`/profile/${profile.slug}`}
        className="flex items-center gap-4"
      >
        {/* wrapper div to prevent the avatar from shrinking */}
        <div>
          <Avatar slug={profile.slug} size={24} />
        </div>
        <span className="truncate">
          <span className="group-hover:underline">{profile.name}</span>
          {' • '}
          {BigInt(count).toLocaleString()} item{s(BigInt(count))}
        </span>
      </Link>
    </li>
  )
}

export default React.memo(MinterRow)
