import { memo, useCallback, useMemo, useState } from 'react'
import { Modal, ModalContent } from 'components/Modal'
import FlagFilled from 'components/icons/FlagFilled'
import { Contract } from 'utils/api'
import classNames from 'utils/classnames'
import { lazy } from 'utils/lazy'
import { textSecondary } from 'utils/theme'
import { useAccount } from 'utils/useAccount'
import { useOpenConnectModal } from 'utils/web3/walletconnect'
import { ReportModalStep } from './types'

const ReportForm = lazy({
  loader: async () => (await import('./ReportForm')).ReportForm,
  Fallback: () => null,
})

type Props = {
  collectionContract: Contract
}

const Report = ({ collectionContract }: Props) => {
  const { isWalletConnected } = useAccount()
  const { openConnectModal } = useOpenConnectModal()

  const [activeStep, activeStepSet] = useState<ReportModalStep>()

  const handleReportClick = useCallback(() => {
    if (isWalletConnected) {
      activeStepSet(ReportModalStep.SubmitReport)
    } else {
      openConnectModal()
    }
  }, [isWalletConnected, openConnectModal])

  const modalContent = useMemo(() => {
    switch (activeStep) {
      case ReportModalStep.SubmitReport:
        return (
          <ModalContent
            title="Report a potential issue with this project"
            description="Choose a reason for your report, and provide more details
            that could help explain the issue"
            content={
              <ReportForm
                collectionContract={collectionContract}
                onComplete={() => {
                  activeStepSet(ReportModalStep.ReportSent)
                }}
              />
            }
          />
        )
      case ReportModalStep.ReportSent:
        return (
          <ModalContent
            title="Thanks! Your report has been submitted"
            description="Reports from the community help let us know when potentially dangerous
        projects are on mint.fun"
          />
        )
      default:
        return null
    }
  }, [activeStep, collectionContract])

  return (
    <>
      <button
        className={classNames(
          textSecondary,
          'flex justify-center items-center gap-1 md:gap-2 h-6 text-xs',
        )}
        onClick={handleReportClick}
      >
        <FlagFilled className="w-4 h-4" />
        <span className="underline">Report an issue with this project</span>
      </button>

      <Modal open={activeStep != null} onClose={() => activeStepSet(undefined)}>
        {modalContent}
      </Modal>
    </>
  )
}

export default memo(Report)
