import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Hex, parseEther } from 'viem'
import { optimismGoerli, zora } from 'viem/chains'
import {
  ChainMismatchError,
  useBalance,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useSwitchNetwork,
} from 'wagmi'
import { WriteContractResult } from 'wagmi/actions'
import { TransactionButtonUnstyled } from 'components/TransactionButton'
import { handleTransactionFailureError } from 'components/TransactionButton/utils'
import TranslucentMaterial from 'components/TranslucentMaterial'
import WarningIcon from 'components/icons/WarningIcon'
import {
  AddressHash,
  ChainId,
  Collection,
  MintButtonSettings,
  MintStatus,
  clientRetry,
  contractMintCountEndpoint,
  fundropPointsEndpoint,
  makeContract,
  useAPIResponse,
} from 'utils/api'
import { defaultChain } from 'utils/chains'
import classnames from 'utils/classnames'
import { useMerkleProofForRoot } from 'utils/lanyard'
import { mutateRead } from 'utils/swr'
import { textSecondary } from 'utils/theme'
import {
  createBridgeTransactionPendingToast,
  createNetworkMismatchToast,
  createTransactionToast,
  dismissToast,
} from 'utils/toasts'
import { useAccount } from 'utils/useAccount'
import { encodeFunctionDataWithMintFunAttribution } from 'utils/web3/helpers'
import { useOpenConnectModal } from 'utils/web3/walletconnect'
import MintStatusChip from '../MintStatusChip'
import { ThemeProps } from './types'

const zoraPassAddress =
  '0x22222222d2B432Ec420032C46fAe3Cda0e029dCc' as AddressHash

const zoraPassChainId: ChainId =
  defaultChain.id === 1 ? zora.id : optimismGoerli.id

export const zoraPassContract = makeContract({
  address: zoraPassAddress,
  chainId: zoraPassChainId,
})

const l1OptimismPortal: AddressHash =
  defaultChain.id === 1
    ? // https://docs.zora.co/docs/zora-network/network ()
      ('0x1a0ad011913A150f69f6A19DF447A0CfD9551054' as AddressHash)
    : // https://community.optimism.io/docs/useful-tools/networks/#contract-addresses-2
      ('0x5b47E1A08Ea6d985D6649300584e6722Ec4B1383' as AddressHash)

const showAllowlistWarningAtCountLeft = 100
const allowlistReward = (
  <>
    0.00222 <span className="uppercase">ETH</span>
  </>
)
const allowlistRewardBig = parseEther('0.00222')
const publicReward = (
  <>
    0.00222 <span className="uppercase">ETH</span>
  </>
)

const publicRewardAria = '0.001 ETH'

type ZoraPassState =
  | { type: 'notConnected' }
  | { type: 'loading' }
  | { type: 'mintableAllowlist'; proof: Hex[] }
  | { type: 'mintablePublic' }
  | { type: 'minted' }
  | { type: 'mintedOut' }

const bodyCopy: Record<
  ZoraPassState['type'],
  (props: { displayName: string | undefined }) => ReactNode | undefined
> = {
  notConnected: () =>
    `Connect your wallet to see if you're on the allowlist for the mint.fun x Zora Bridge Pass 2.0!`,
  loading: () => undefined,
  mintableAllowlist: ({ displayName }) => (
    <>
      Welcome, {displayName ?? ''}.
      {`\nYou're on the allowlist for the mint.fun x Zora Bridge\u00a0Pass 2.0.\n\nMint now to get a bridge pass and receive `}
      {allowlistReward}
      {` on Zora Network, courtesy of Zora. Limited to the first\u00a020,000\u00a0minters.`}
    </>
  ),
  mintablePublic: ({ displayName }) => (
    <>
      Welcome, ${displayName ?? ''}
      {`.\nYou can now bridge to Zora on mint.fun!\n\nMint a mint.fun x Zora Bridge Pass 2.0 to get a bridge pass and receive `}
      {publicReward}
      {` on\u00a0Zora\u00a0Network.`}
    </>
  ),
  mintedOut: () =>
    `The mint for this has\u00a0completed!\nExplore more mints on Zora\u00a0Network`,
  minted: () => (
    <>
      Minted! Your <span className="uppercase">ETH</span> and Bridge Pass should
      appear in your wallet soon. You can mint more Bridge Passes by bridging
      0.0222 <span className="uppercase">ETH</span>.,
    </>
  ),
}

/** null means no button */
const buttonLabelCopy: Record<ZoraPassState['type'], ReactNode> = {
  notConnected: 'Connect Wallet',
  loading: <>Mint for {publicReward}</>,
  mintablePublic: <>Mint for {publicReward}</>,
  minted: <>Mint for {publicReward}</>,
  mintableAllowlist: 'Mint for FREE',
  mintedOut: null,
}

const buttonLabelAriaCopy: Record<ZoraPassState['type'], string | null> = {
  notConnected: 'Connect Wallet',
  loading: `Mint for ${publicRewardAria}}`,
  mintablePublic: `Mint for ${publicRewardAria}}`,
  minted: `Mint for ${publicRewardAria}}`,
  mintableAllowlist: 'Mint for FREE',
  mintedOut: null,
}

export default function ZoraPassTheme({ collection }: ThemeProps) {
  const mintStatus = useZoraPassMintStatus()
  const state = useZoraPassState(mintStatus === 'mintingNow')

  return (
    <div className="flex flex-col items-center gap-y-6 mb-64">
      <MintStatusChip mintStatus={mintStatus} />
      <ZoraPassImage />
      <PassButton collection={collection} state={state} />
      <MintClosingWarning state={state} />
      <PassDetails collection={collection} state={state} />
    </div>
  )
}

const getMintCount = async (address: AddressHash) => {
  const res = await clientRetry(
    'GET',
    contractMintCountEndpoint({
      contract: zoraPassContract,
      address: address,
    }),
  )

  const json: { count?: number } = await res.json()

  return json.count
}

const waitForMint = async (address: AddressHash) => {
  const startingCount = (await getMintCount(address)) ?? 0

  // l2 bridge takes at least 1 minute, so we wait 118 seconds before checking
  await new Promise((resolve) => setTimeout(resolve, 118 * 1000))

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const count = await getMintCount(address)

    if (count !== undefined && count - startingCount > 0) {
      await mutateRead(
        contractMintCountEndpoint({ contract: zoraPassContract, address }),
      )
      break
    }

    await new Promise((resolve) => setTimeout(resolve, 4000))
  }
}

function PassButton({
  collection,
  state,
}: {
  collection: Collection
  state: ZoraPassState
}) {
  const callData = useMemo<Hex | undefined>(() => {
    if (state.type === 'mintableAllowlist') {
      return encodeFunctionDataWithMintFunAttribution({
        abi: mintPassAbi,
        functionName: 'mint',
        args: [state.proof],
      })
    }

    if (state.type === 'mintablePublic' || state.type === 'minted') {
      return encodeFunctionDataWithMintFunAttribution({
        abi: mintPassAbi,
        functionName: 'mintPublic',
      })
    }

    return undefined
  }, [state])

  const mintCloseTime = useMintCloseTimeForMintPass()

  const mintIsOpen = useMemo(() => {
    if (mintCloseTime === undefined) {
      return false
    }

    return mintCloseTime > new Date()
  }, [mintCloseTime])

  const value =
    state.type === 'mintablePublic' || state.type === 'minted'
      ? parseEther('0.00222')
      : 0n

  const { config, error: prepareError } = usePrepareContractWrite({
    abi: portalAbi,
    address: l1OptimismPortal,
    chainId: defaultChain.id,
    functionName: 'depositTransaction',
    args: [zoraPassAddress, value, 222_000n, false, callData ?? '0x'],
    value,
    enabled: callData !== undefined,
  })
  const { writeAsync } = useContractWrite({
    ...config,
    request: {
      ...config.request,
      // 1.5x the gas limit
      gas:
        config.request?.gas !== undefined
          ? (config.request.gas * 3n) / 2n
          : undefined,
    },
  })

  const { address, chainId } = useAccount()
  const { switchNetwork } = useSwitchNetwork()
  const { openConnectModal } = useOpenConnectModal()

  const buttonDisabled = useMemo(() => {
    if (
      state.type === 'mintableAllowlist' ||
      state.type === 'mintablePublic' ||
      state.type === 'minted'
    ) {
      if (
        writeAsync === undefined &&
        !(prepareError instanceof ChainMismatchError)
      ) {
        return true
      }

      if (!mintIsOpen) {
        return true
      }
    }

    if (state.type === 'loading') {
      return true
    }

    return false
  }, [mintIsOpen, prepareError, state.type, writeAsync])

  const [buttonPending, buttonPendingSet] = useState(false)

  const handleButtonClick = useCallback(async () => {
    if (address === undefined) {
      openConnectModal()
      return
    }

    if (chainId !== defaultChain.id) {
      createNetworkMismatchToast(defaultChain.name)
      switchNetwork?.(defaultChain.id)
      return
    }

    if (writeAsync === undefined) {
      return
    }

    let txResult: WriteContractResult | undefined
    try {
      buttonPendingSet(true)
      txResult = await writeAsync()
    } catch (e) {
      buttonPendingSet(false)
      handleTransactionFailureError(e)
      console.error(e)
      return
    }

    const pendingToastId = await createBridgeTransactionPendingToast(
      txResult.hash,
      defaultChain.id,
      zoraPassChainId,
    )

    try {
      // wait for tx to show up in our indexer
      await waitForMint(address)

      createTransactionToast({
        tokenIds: [], // just show collection name
        transactionHash: '', // not needed
        collectionContract: zoraPassContract,
        collectionName: collection.name,
        isFunContract: collection.isFunContract,
        minterAddress: address,
        toastId: pendingToastId,
        skipTokenIdCheck: true,
      })

      buttonPendingSet(false)

      // this will error if the user hasn't minted their fundrop pass
      // we're going to just ignore it
      mutateRead(fundropPointsEndpoint({ address: address ?? '' }))
    } catch (e) {
      dismissToast(pendingToastId)
      handleTransactionFailureError(e)
    }

    buttonPendingSet(false)
  }, [
    address,
    chainId,
    collection.isFunContract,
    collection.name,
    openConnectModal,
    switchNetwork,
    writeAsync,
  ])

  const mbs = useMemo<MintButtonSettings>(
    () => ({
      backgroundColor: '#e1e1e1',
      textColor: '#000000',
    }),
    [],
  )

  const copy = buttonLabelCopy[state.type]
  const ariaLabel = buttonLabelAriaCopy[state.type]

  if (copy === null || copy === undefined || ariaLabel === null) {
    return null
  }

  return (
    <TransactionButtonUnstyled
      label={copy}
      ariaLabel={ariaLabel}
      pending={buttonPending}
      disabled={buttonDisabled}
      onClick={handleButtonClick}
      className={classnames('md:w-[35.5rem] md:h-[4.7rem]')}
      buttonSettings={mbs}
    />
  )
}

function MintClosingWarning({ state }: { state: ZoraPassState }) {
  const [showCloseToClosingWarning, showCloseToClosingWarningSet] =
    useState(false)

  const mintCloseTime = useMintCloseTimeForMintPass()
  const { state: allowlistAlmostOut, refetch: refetchAlmostMintedOut } =
    useAllowlistAlmostOut()

  useEffect(() => {
    if (mintCloseTime === undefined || allowlistAlmostOut === 'loading') {
      return
    }

    const check = (initial: boolean) => {
      // if it's between 10 minutes and 0 minutes before the mint close time
      // show the warning, or if we're close to it minting out
      const now = new Date()

      if (
        (mintCloseTime.getTime() - now.getTime() < 0 &&
          mintCloseTime.getTime() - now.getTime() > -10 * 60 * 1000) ||
        (state.type === 'mintableAllowlist' && allowlistAlmostOut === 'close')
      ) {
        showCloseToClosingWarningSet(true)
      } else {
        showCloseToClosingWarningSet(false)
      }

      if (!initial) {
        refetchAlmostMintedOut()
      }
    }

    check(true)

    const id = setInterval(() => {
      check(false)
    }, 10_000)
    return () => clearInterval(id)
  }, [allowlistAlmostOut, mintCloseTime, refetchAlmostMintedOut, state.type])

  if (!showCloseToClosingWarning || state.type === 'mintedOut') {
    return null
  }

  return (
    <TranslucentMaterial className="flex flex-row items-center justify-center gap-x-3 self-start py-3">
      <WarningIcon className="flex-shrink-0 text-[#FFC700]" />
      {state.type === 'mintableAllowlist' && allowlistAlmostOut === 'close'
        ? 'There are limited free passes left and your transaction may not be processed on the Zora network due to transaction time.'
        : 'The mint is closing soon and your transaction may not be processed on the Zora network due to transaction time.'}
    </TranslucentMaterial>
  )
}

function PassDetails({
  collection,
  state,
}: {
  collection: Collection
  state: ZoraPassState
}) {
  const { displayName } = useAccount()

  const copy = useMemo(
    () => bodyCopy[state.type]({ displayName }),
    [displayName, state.type],
  )

  return (
    <div
      className={classnames(
        'flex flex-col',
        'w-full rounded-xl px-10 py-20',
        'border-2 border-neutral-200 dark:border-neutral-800',
        'text-center',
        'gap-y-8',
      )}
    >
      {copy !== undefined && copy !== null && (
        <div className="flex flex-col gap-y-2 font-display text-base sm:text-xl">
          {copy
            .toString()
            .split('\n')
            .map((line, i) => (
              <div key={i}>{line}</div>
            ))}
        </div>
      )}
      {collection.totalMints !== '0' && (
        <div className="flex flex-col items-center gap-y-1 mt-4">
          <div className="font-bold font-display text-base sm:text-xl">
            {BigInt(collection.totalMints).toLocaleString()}
          </div>
          <div
            className={classnames(
              'font-bold font-display text-sm',
              textSecondary,
            )}
          >
            Minted
          </div>
        </div>
      )}
    </div>
  )
}

function ZoraPassImage() {
  return (
    <div className={classnames('relative overflow-hidden')}>
      <img
        src="https://image.mux.com/tAy6528f5MVnLRQwNjIaU72xPf1MpjUNu5TTem02Y5o4/animated.webp?width=500&end=10"
        className="rounded-lg"
        width={500}
        height={500}
      />
    </div>
  )
}

/////////////////////////////////////////
// Hooks

const useMerkleRootForMintPass = () =>
  useContractRead({
    abi: mintPassAbi,
    address: zoraPassAddress,
    functionName: 'merkleRoot',
    chainId: zoraPassChainId,
  }).data

const useMintCloseTimeForMintPass = () => {
  const { data: closeTimeNum } = useContractRead({
    abi: mintPassAbi,
    address: zoraPassAddress,
    chainId: zoraPassChainId,
    functionName: 'mintEnd',
  })

  return useMemo(() => {
    if (closeTimeNum === undefined) {
      return undefined
    }

    return new Date(Number(closeTimeNum) * 1000)
  }, [closeTimeNum])
}

const useAllowlistAlmostOut = (): {
  state: 'safe' | 'close' | 'allOut' | 'loading'
  refetch: () => void
} => {
  const { data, refetch } = useBalance({
    address: zoraPassAddress,
    chainId: zoraPassChainId,
  })

  const balance = data?.value
  const amountLeft =
    balance !== undefined ? balance / allowlistRewardBig : undefined

  if (balance === undefined || amountLeft === undefined) {
    return { state: 'loading', refetch }
  }

  if (balance < allowlistRewardBig) {
    return { state: 'allOut', refetch }
  }

  if (amountLeft < showAllowlistWarningAtCountLeft) {
    return { state: 'close', refetch }
  }

  return { state: 'safe', refetch }
}

function useZoraPassState(mintOpen: boolean): ZoraPassState {
  const { isWalletConnected, isLoadingWallet, address } = useAccount()

  const { data: mintCountResponse } = useAPIResponse<{ count: `${bigint}` }>(
    contractMintCountEndpoint({ contract: zoraPassContract, address }),
    undefined,
    { skipFetching: address === undefined },
  )

  const merkleRoot = useMerkleRootForMintPass()

  const { proof, status: merkleProofStatus } = useMerkleProofForRoot(
    merkleRoot ?? '',
    address,
    false,
  )

  const { state: allowlistAlmostOut } = useAllowlistAlmostOut()

  return useMemo<ZoraPassState>(() => {
    if (isLoadingWallet) {
      return { type: 'loading' }
    }

    if (!mintOpen) {
      return { type: 'mintedOut' }
    }

    if (!isWalletConnected) {
      return { type: 'notConnected' }
    }

    if (mintCountResponse === undefined || allowlistAlmostOut === 'loading') {
      return { type: 'loading' }
    }

    if (
      mintCountResponse?.count !== undefined &&
      BigInt(mintCountResponse.count) > 0
    ) {
      return { type: 'minted' }
    }

    if (merkleProofStatus === 'loading') {
      return { type: 'loading' }
    }

    if (
      proof !== undefined &&
      (allowlistAlmostOut === 'safe' || allowlistAlmostOut === 'close')
    ) {
      return { type: 'mintableAllowlist', proof }
    }

    return { type: 'mintablePublic' }
  }, [
    allowlistAlmostOut,
    isLoadingWallet,
    isWalletConnected,
    merkleProofStatus,
    mintCountResponse,
    mintOpen,
    proof,
  ])
}

function useZoraPassMintStatus() {
  const [mintStatus, mintStatusSet] = useState<MintStatus>('mintingNow')

  const mintCloseTime = useMintCloseTimeForMintPass()

  useEffect(() => {
    if (mintCloseTime === undefined) {
      return
    }

    const id = setInterval(() => {
      const now = new Date()

      if (now > mintCloseTime) {
        mintStatusSet('mintedOut')
      } else {
        mintStatusSet('mintingNow')
      }
    }, 1000)

    return () => clearInterval(id)
  }, [mintCloseTime])

  return mintStatus
}

/////////////////////////////////////////
// ABIs

const mintPassAbi = [
  {
    inputs: [],
    name: 'ApprovalCallerNotOwnerNorApproved',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ApprovalQueryForNonexistentToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'BalanceQueryForZeroAddress',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InvalidProof',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InvalidTokenId',
    type: 'error',
  },
  {
    inputs: [],
    name: 'MintClosed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'MintERC2309QuantityExceedsLimit',
    type: 'error',
  },
  {
    inputs: [],
    name: 'MintToZeroAddress',
    type: 'error',
  },
  {
    inputs: [],
    name: 'MintZeroQuantity',
    type: 'error',
  },
  {
    inputs: [],
    name: 'MintedAlready',
    type: 'error',
  },
  {
    inputs: [],
    name: 'MustBeBridged',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NeedsMerkleRoot',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotEnoughFunds',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OnlyOwnerOrMetadataUpdater',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OnlyTokenOwner',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OwnerQueryForNonexistentToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OwnershipNotInitializedForExtraData',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TransferCallerNotOwnerNorApproved',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TransferFromIncorrectOwner',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TransferToNonERC721ReceiverImplementer',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TransferToZeroAddress',
    type: 'error',
  },
  {
    inputs: [],
    name: 'URIQueryForNonexistentToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'bridgeReward',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'merkleRoot',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'minimumBridgeFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32[]',
        name: '_proof',
        type: 'bytes32[]',
      },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'mintEnd',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'mintPublic',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '_data',
        type: 'bytes',
      },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'approved',
        type: 'bool',
      },
    ],
    name: 'setApprovalForAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const

const portalAbi = [
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'version',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'opaqueData',
        type: 'bytes',
      },
    ],
    name: 'TransactionDeposited',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_value', type: 'uint256' },
      { internalType: 'uint64', name: '_gasLimit', type: 'uint64' },
      { internalType: 'bool', name: '_isCreation', type: 'bool' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'depositTransaction',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
] as const
